import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './components/services/services';
import BiAnalytics from './components/services/bia-nalytics';
import CloudApplications from './components/services/cloudapplications';
import AiAutomation from './components/services/aiautomation';
import AboutUs from './components/about';
import Careers from './components/company/career';
import Work from './components/work/work';
import Blog from './components/blog';
import Contact from './components/contact';
import BlogTittle from './components/blogtittle';
import PrivacyPolicy from './components/privacy-policy';
import BrandGuidelines from './components/brand-guidline';
import ScheduleCall from './components/company/schedule-call';
import BlogTittleOne from './components/blogtittle-one';
import BlogTittleTwo from './components/blogtittle-two';
import BlogTitttleThree from './components/blogtittle-three'

import WebiNar from './webiner/webinar';
import BlogTittleFourth from './components/blog-fourth';
import Job from './components/company/job';
import PageNotFound from './components/page-not-found';
import BlogMultimodalAi from './components/blog-multimodal-ai';
import BlogDemocratizingAI from './components/blog-democratizing-ai';
import BlogDemystifying from './components/blog-demystifying';
import BlogEconomic from './components/blog-economic';
import BlogHackersAI from './components/blog-hackersai';
import BlogRealWorld from './components/blog-real-world';
import BlogRobots from './components/blog-robots';
import BlogUpskilling from './components/blog-upskillin';
import RevolutionizingBusiness from './components/blog-revolutionizing-business';
import GenerativeDeliverin from './components/blog-generative-ai-deliverin';

import PredictiveMaintenanceEnergySector from './components/blog-predictive-maintenance-energy';
import UnderstandingAIHallucinations from './components/blog-understanding-ai-hallucinations';
import SmallerModelsBiggerImpact from './components/blog-smaller-models-bigger-impact';


function App() {
  return (
    <div className="App">
     <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/services/enterprise-mobility" element={<Services/>} />
           <Route path="/services/bi-analytics" element={<BiAnalytics/>} />
           <Route path="/services/cloud-applications" element={<CloudApplications/>} />
           <Route path="/services/ai-automation" element={<AiAutomation/>} />
            <Route path="/about" element={<AboutUs/>} />
            <Route path="/careers" element={<Careers/>} />
            <Route path="/work" element={<Work/>} />
            <Route path="/blogs" element={<Blog/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/blogtittle" element={<BlogTittle/>} />
           
           
            <Route path="/webinar" element={<WebiNar/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/brand-guidelines" element={<BrandGuidelines/>} />
            <Route path="/schedule-call" element={<ScheduleCall/>} />
            <Route path="/job/:jobId" element={<Job/>} />

            <Route path="/blogs/smaller-models-bigger-impact" element={<SmallerModelsBiggerImpact/>} />
            <Route path="/blogs/understanding-ai-hallucinations" element={<UnderstandingAIHallucinations/>} />
            <Route path="/blogs/predictive-maintenance-energy" element={<PredictiveMaintenanceEnergySector/>} />

            <Route path="/blogs/generative-ai-improve-data-analytics" element={<BlogTittleOne/>} />
            <Route path="/blogs/generative-ai-vs-traditional-ml" element={<BlogTittleTwo/>} />
            <Route path="/blogs/generative-ai-across-various-industries" element={<BlogTitttleThree/>} />
            <Route path="/blogs/multi-cloud-adoption" element={<BlogTittleFourth/>} />  
            <Route path="/blogs/multimodal-ai-digital-transformation" element={<BlogMultimodalAi/>} />
            <Route path="/blogs/democratizing-power-ai" element={<BlogDemocratizingAI/>} />
            <Route path="/blogs/successful-digital-transformation-ai" element={<BlogDemystifying/>} />
            <Route path="/blogs/economic-potential-generative-ai" element={<BlogEconomic/>} />
            <Route path="/blogs/hackers-vs-ai-cybersecurity-revolution" element={<BlogHackersAI/>} />
            <Route path="/blogs/generative-ai-applications" element={<BlogRealWorld/>} />
             <Route path="/blogs/automation-can-supercharge" element={<BlogRobots/>} />
             <Route path="/blogs/upskilling-your-workforce" element={<BlogUpskilling/>} />
             <Route path="/blogs/revolutionizing-business-strategy" element={<RevolutionizingBusiness/>} />
             <Route path="/blogs/generative-ai-deliverin" element={<GenerativeDeliverin/>} />

            {/* Catch-all Route for unmatched URLs */}
            <Route path="*" element={<PageNotFound/>} />
        </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
