import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './logo'

const AllBlog = () => {
  return (
    <div>
          <section className='best__blog mt-5'>
                <div className='container-fluid px-5'>
                <div  className="row ">
                <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/predictive-maintenance-energy">
                         <div className='service__box__image'>
                              <img src='/images/card/harnessing-predictive-card.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Harnessing Predictive Maintenance and Deep Learning for the Energy Sector</h3>
                                  <p className=''>23rd August, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/understanding-ai-hallucinations">
                         <div className='service__box__image'>
                              <img src='/images/card/understanding-hallucinations-card.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Understanding AI Hallucinations: Causes, Implications, and Prevention</h3>
                                  <p className=''>6th August, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/smaller-models-bigger-impact">
                         <div className='service__box__image'>
                              <img src='/images/card/smaller-models-card.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Smaller Models, Bigger Impact: The Rise of Efficient AI</h3>
                                  <p className=''>20th June, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>

                <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/generative-ai-deliverin">
                         <div className='service__box__image'>
                              <img src='/images/card/generative-ai-delivering.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Is Generative AI Delivering on Its Promise in India?</h3>
                                  <p className=''>29th May, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/revolutionizing-business-strategy">
                         <div className='service__box__image'>
                              <img src='/images/card/revolutionizing-business-strategy.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Industry 5.0: Revolutionizing Business Strategy for a Human-Centric, Resilient, and Sustainable Future</h3>
                                  <p className=''>18th May, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                         <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/multimodal-ai-digital-transformation">
                         <div className='service__box__image'>
                              <img src='/images/card/multimodal-ai.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                                  <h3 className='blog-box-title'>Multimodal AI: The New Frontier in Digital Transformation</h3>
                                  <p className=''>10th May, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/automation-can-supercharge">
                         <div className='service__box__image'>
                              <img src='/images/card/card-robots.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>Robots Don't Steal Jobs, They Amplify Them: How AI Automation Can Supercharge Your Business</h3>
                                  <p> 26th April, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/hackers-vs-ai-cybersecurity-revolution">
                         <div className='service__box__image'>
                              <img src='/images/card/card-hackers.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>Hackers vs. AI: How Artificial Intelligence is Revolutionizing Cybersecurity</h3>
                                  <p> 22nd April, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/successful-digital-transformation-ai">
                         <div className='service__box__image'>
                              <img src='/images/card/card-demystifying.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>Demystifying the Black Box: Why Explainable AI is Key to Successful Digital Transformation</h3>
                                  <p> 19th April, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/upskilling-your-workforce">
                         <div className='service__box__image'>
                              <img src='/images/card/card-upskilling.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>Upskilling Your Workforce for the Future: How AI Can Become Your Ultimate L&D Partner</h3>
                                  <p> 17th April, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/democratizing-power-ai">
                         <div className='service__box__image'>
                              <img src='/images/card/card-democratizing.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>Democratizing AI: How SMEs in India Can Harness AI's Power Without Breaking the Bank</h3>
                                  <p> 29th March, 2024</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/economic-potential-generative-ai">
                         <div className='service__box__image'>
                              <img src='/images/card/card-economic.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>The Economic Potential of Generative AI: A Catalyst for Growth</h3>
                                  <p> 19th July, 2023</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                               <Link to="/blogs/generative-ai-across-various-industries">
                               <div className='service__box__image'>
                              <img src='/images/card/blog-03.webp' className='' alt='' width="100%"/>
                              <div className='best__blog__content'>
                              <h3 className='blog-box-title'>Real-World Applications of Generative AI Across Various Industries</h3>
                                  <p>19th July, 2023</p>
                               </div>
                            </div> 
                                </Link>
                            </div>
                            <div className='col-lg-3 col-md-6 col-6 blog-box' blog-tittle-three>
                            <Link to="/blogs/multi-cloud-adoption">
                                    <div className='service__box__image'>
                                        <img src='/images/card/blog-card-04.webp' className='' alt='' width="100%"/>
                                        <div className='best__blog__content'>
                                        <h3 className='blog-box-title'>Multi-Cloud Adoption: Challenges and Best Practices for a Distributed Environment</h3>
                                            <p>29th July, 2023</p>
                                        </div>
                                        </div> 
                                    </Link> 
                            </div>
                     <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/generative-ai-improve-data-analytics">
                         <div className='service__box__image'>
                              <img src='/images/card/blog-01.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content'>
                               <h3 className='blog-box-title'>How Generative AI Can Improve Data Analytics and Decision-Making</h3>
                                  <p>11th July, 2023</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                               <Link to="/blogs/generative-ai-vs-traditional-ml">
                               
                                    <div className='service__box__image'>
                                    <img src='/images/card/blog-02.webp' className='' alt='' width="100%"/>
                                    <div className='best__blog__content'>
                                    <h3 className='blog-box-title'>Generative AI vs. Traditional Machine Learning: What's the Difference?</h3>
                                        <p>5th July, 2023</p>
                                    </div>
                                    </div> 
                               </Link>
                            </div>
                           
                            <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/generative-ai-applications">
                         <div className='service__box__image'>
                              <img src='/images/card/card-realworld.webp' className='' alt='' width="100%"/>
                               <div className='best__blog__content '>
                               <h3 className='blog-box-title'>Real-World Applications of Generative AI Across Various Industries</h3>
                                  <p> 1st June, 2023</p>
                               </div>
                            </div> 
                        </Link>  
                        </div>

                </div>
                </div>
            </section>
              
    </div>
  )
}

export default AllBlog
