import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import {useState, useEffect} from 'react';



const BrandGuidelines = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const Height = {
        height: '40vh'
        // Add more CSS properties as needed
      };
  return (
    <div>
        <div className='bg__contact pb-4'>
           <NavbarHeader color="#F5E5BB"/>
                     <div className='padding_banner'>
                     <div className='container-fluid px-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='services_page_content my-5'>
                            <h1 className='services_page_title'>Brand Guidelines</h1>
                          </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='services_page_content my-5'>
                                <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.</p>
                                </div>
                            </div>
                          </div>

                    
                         </div>
                     </div>
                    </div>

                    <Container style={Height}>

                       

                      </Container>


  
                       

            
                            
              
      
       <div className='default mt-5'>
  
           
      <Footer color="#00000" /> 
       </div>
    </div>
  )
}

export default  BrandGuidelines
