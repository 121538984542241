import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
//importing useState and useEffect
import { useState, useEffect } from 'react';
import SEO from './seo';
import AllBlog from './all-blog';

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <SEO
                title='Blog - Acompworld: Latest Trends in AI, Cloud Computing & Digital Transformation'
                description="Stay updated with the latest trends and insights in generative AI, cloud computing, and digital transformation from Acompworld's experts."
                keywords=''
            />
            <div className='bg__blogtittle pb-4'>
                <NavbarHeader color="#F8D8F6" />
                <div className='padding_banner marging-d-pading'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>Insights</h1>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Stay ahead of the curve with the latest insights on digital transformation, technology trends, and best practices. Our blog aims to equip you with the knowledge and strategies you need to thrive in a rapidly evolving digital landscape.</p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/work-banner.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>

            <div>

            </div>

            <section className="work__line mt-5 px-5" style={{ display: "none" }}>
                <Container fluid>
                    <Row>
                        <Col xs="11">
                            <p className='short-by'>Sort by:  </p>
                        </Col>
                        <Col xs="1">
                            <Form.Select aria-label="Default select example" className='select-border'>
                                <option>newest</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>

                <hr className='' />
            </section>


            <AllBlog />













            <section className="work__line mt-5 px-5">


                <hr className='' />
            </section>

            <Container fluid className='px-5' style={{ display: "none" }}>
                <Pagination>
                    <Pagination.First />
                    <Pagination.Prev className='hide__mobile' />
                    <Pagination.Item>Previous page</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>Next page</Pagination.Item>
                    <Pagination.Next className='hide__mobile' />
                    <Pagination.Last />
                </Pagination>
            </Container>

            <div className='default mt-5'>


                <Footer color="#00000" />
            </div>
        </div>
    )
}

export default Blog
