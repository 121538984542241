import api from "./api";
import APIError from "../exceptions/APIError";

class LeadService {
    async add(data) {       
        try {
            const response = await api.post("/leads", data);
            return response.data;
        } catch (error) {
            throw APIError.internal(error.message);
        }
    }
}

export default new LeadService();