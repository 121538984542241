import React from 'react'
import NavbarHeader from './navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SEO from '../seo';

const AiAutomation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [visibleSections, setVisibleSections] = useState([]);




    return (
        <div>
             <SEO
  title='AI & Automation - Acompworld: Generative AI & Intelligent Process Automation Solutions'
  description='Transform your business with intelligent process automation, robotic process automation (RPA), and generative AI services from Acompworld.'
  keywords=''
/>
            <div className='bg__aiautomation pb-4'>
                <NavbarHeader color="#C1F9F9" />
                <div className='padding_banner marging-d-pading'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>AI & Automation</h1>
                                    <h2>Unlocking the Power of AI and Automation with Acompworld</h2>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>
                                        Stay ahead in the digital era with Acompworld's AI and automation solutions. Harness machine learning, natural language processing, and robotic process automation to drive efficiency, improve decision-making, and achieve cost savings. Unlock the power of AI and automation today!


                                    </p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/automation.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>
            <section className="mt-5" >

                <div className='container-fluid px-5'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Information Search and Synthesis</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Customer Experience</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Intelligent Automation</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Enterprise content</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="work__line">
                <hr className='line__gray' />
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6' >
                            <div className="best__service_content">
                                <h3>Information Search and Synthesis</h3>
                                <p>
                                    Enhance your information search and synthesis capabilities with the power of AI and automation. By leveraging advanced algorithms, you can access accurate search results, receive personalized recommendations, and gain intelligent insights. Say goodbye to information overload as automated data analysis and efficient summarization save you valuable time. Stay competitive by making informed decisions effortlessly. Embrace the future of information management and experience the transformative potential of AI         </p>
                            </div>
                        </div>
                        <div className='col-lg-1 displaynone-div'></div>

                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image" >
                                <img src='/images/card/ai-auto-card-image.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>



                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/card/ai-auto-card-image.webp' className='' width="100%" />
                            </div>
                        </div>

                        <div className='col-lg-1 displaynone-div'></div>

                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content">
                                <h3>Customer Experience</h3>
                                <p>
                                    Elevate the customer experience through the integration of AI and automation. By leveraging these technologies, businesses can personalize interactions, offer tailored recommendations, and efficiently handle customer inquiries. Anticipate customer needs with predictive analytics, providing proactive solutions. Streamline processes and optimize workflows for a seamless customer journey. Through the precise application of AI and automation, businesses can enhance customer satisfaction and loyalty.        </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content">
                                <h3>Intelligent Automation</h3>
                                <p>
                                    Intelligent Automation combines AI and automation to revolutionize operations. By integrating AI algorithms into automated systems, organizations achieve efficiency, accuracy, and innovation. With cognitive capabilities like natural language processing and machine learning, it analyzes data, makes informed decisions, and enhances customer experiences. Free up resources for strategic initiatives, driving innovation and adaptability. Embrace the future with Intelligent Automation, empowering your organization to thrive in the digital era.         </p>
                            </div>
                        </div>
                        <div className='col-lg-1 displaynone-div'></div>

                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/card/ai-auto-card-image.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>



                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/card/ai-auto-card-image.webp' className='' width="100%" />
                            </div>
                        </div>

                        <div className='col-lg-1 displaynone-div'></div>

                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content">
                                <h3>Enterprise content</h3>
                                <p>
                                    Experience a new level of efficiency and productivity in content creation with the power of AI and automation. Unlock the ability to generate a wide range of professional content, including marketing pitches and engaging articles, at an unprecedented speed. AI algorithms analyze data, personalize content, and optimize performance, ensuring the highest quality output. Automate content curation to effortlessly gather relevant and high-quality material. Embrace the professional advantage of AI and automation for seamless content generation.        </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="work__line">
                <hr className='line__gray' />
            </section>





            <div className='default mt-80'>
                <div className='container-fluid'>
                    <div className='footer__heading'>
                        <h2 class="center">Have a project idea? Let's come together to build something great</h2>
                    </div>
                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn__footer'>Talk to an expert</Link>
                    </div>

                </div>
                <Footer color="#00000" />
            </div>
        </div>
    )
}

export default AiAutomation
