import React from 'react';
import { Col, Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';

function Slider() {
   return (
      <Carousel>
         <Carousel.Item className='slider_index'>
            <img
               className="d-block w-100"
               src='/images/slider-image.webp'
               alt="First slide"
            />
            <Carousel.Caption className='slider_logo'>
               <Container>
                  <Row>
                     <Col className='col-lg-2'>
                        <img src='/images/ntpc.png' alt='' className='img-fluid display-none' />
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>

            <Carousel.Caption>
               <Container>
                  <Row>
                     <Col className='col-lg-6'>
                        <p className='slider_p'>Acompworld's  AI, Databa Analytics and Process transformation solutions have significantly improved India's Largest Power Producer - <b>NTPC</b>'s processes and operations, enhancing efficiency, productivity, and decision-making capabilities.</p>
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>
         </Carousel.Item>

         <Carousel.Item className='slider_index'>
            <img
               className="d-block w-100"
               src='/images/train.webp'
               alt="First slide"
            />
            <Carousel.Caption className='slider_logo'>
               <Container>
                  <Row>
                     <Col className='col-lg-2'>
                        <img src='/images/Indian_Railways.png' alt='' className='img-fluid display-none' />
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>

            <Carousel.Caption>
               <Container>
                  <Row>
                     <Col className='col-lg-6'>
                        <p className='slider_p'>Acompworld has successfully implemented a robust railway coach maintenance system and data analytics solutions for <b>Indian Railways</b>, aimed at enhancing overall operations, maintenance processes, and passenger experiences.</p>
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>
         </Carousel.Item>
         <Carousel.Item className='slider_index'>
            <img
               className="d-block w-100"
               src='/images/slider-image01.webp'
               alt="First slide"
            />
            <Carousel.Caption className='slider_logo margin-down'>
               <Container>
                  <Row>
                     <Col className='col-lg-2'>
                        <img src='/images/indiaexim-bank.png' alt='' className='img-fluid display-none' />
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>

            <Carousel.Caption>
               <Container>
                  <Row>
                     <Col className='col-lg-6'>
                        <p className='slider_p'>Acompworld has played a pivotal role in elevating <b>Export Import Bank of India</b>'s operational efficiency, user engagement, and data-driven decision-making by building their web portals, content management system and digital dashboards</p>
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>
         </Carousel.Item>


         <Carousel.Item className='slider_index'>
            <img
               className="d-block w-100"
               src='/images/adtiya.webp'
               alt="First slide"
            />
            <Carousel.Caption className='slider_logo'>
               <Container>
                  <Row>
                     <Col className='col-lg-2'>
                        <img src='/images/abg.png' alt='' className='img-fluid display-none' />
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>

            <Carousel.Caption>
               <Container>
                  <Row>
                     <Col className='col-lg-6'>
                        <p className='slider_p'>Empowering <b>Aditya Birla Group</b>, a $44 billion global conglomerate with a legacy spanning over 150 years, by delivering cutting-edge IT expertise through our bespoke corporate training programs across India</p>
                     </Col>
                  </Row>
               </Container>
            </Carousel.Caption>
         </Carousel.Item>




      </Carousel>
   );
}

export default Slider;