import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import countryList from '../helper/countryList';
import Toastify from 'toastify-js';

import LeadService from '../services/LeadService';
import SEO from './seo';

const Contact = () => {

  const navigate = useNavigate();

  const initStateData = {
    name: "",
    email: "",
    phone_country: "India (+91)",
    phone: "",
    company_name: "",
    organization_size: "",
    industry: "",
    services: "",
    message: "NA"
  };

  const employeeRanges = [
    '1-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1,000 employees',
    '1,001-5,000 employees',
    '5,001-10,000 employees',
    '10,001-50,000 employees',
    '50,001-100,000 employees'
  ];

  const industries = [
    'Agriculture',
    'Automotive',
    'Banking & Financial Services',
    'Construction',
    'Consulting',
    'Consumer Goods & Services',
    'Education',
    'Energy & Utilities',
    'Engineering & Manufacturing',
    'Entertainment & Media',
    'Environment & Natural Resource',
    'Government & Public Sector',
    'Healthcare & Pharmaceuticals',
    'Hospitality & Tourism',
    'Insurance',
    'IT & Technology',
    'Legal Services',
    'Non-Profit',
    'Real Estate',
    'Retail',
    'Telecommunications',
    'Transportation & Logistics'
  ];

  //Error Mesaages for different fields
  const [errorName, setErrorName] = useState('Please enter name');
  const [errorEmail, setErrorEmail] = useState('Please enter email');
  const [errorPhone, setErrorPhone] = useState('Please enter phone');
  const [errorCompanyName, setErrorCompanyName] = useState('Please enter organization name');
  const [errorCompanySize, setErrorCompanySize] = useState('Please select organization size');
  const [errorIndustry, setErrorIndustry] = useState('Please select industry/sector');
  const [errorService, setErrorService] = useState('Please select service');

  //Valid State for different fields
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [validCompanySize, setValidCompanySize] = useState(false);
  const [validIndustry, setValidIndustry] = useState(false);
  const [validService, setValidService] = useState(false);

  const [isResponseSubmitted, setIsResponseSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [contactData, setContactData] = useState(initStateData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === 'phone') {
    //   const sanitizedValue = value.replace(/\D/g, '');
    //   setContactData({ ...contactData, [name]: sanitizedValue });
    // }
    // else {
    //   setContactData({ ...contactData, [name]: value });
    // }

    setContactData({ ...contactData, [name]: value });
  }

  //creating function to handle validation of form fields and return boolean value
  const validateForm = () => {
    try {
      var flagerror = false;      

      //Set default error message
      setErrorName('Please enter name');
      setErrorEmail('Please enter email');
      setErrorPhone('Please enter phone');
      setErrorCompanyName('Please enter organization name');
      setErrorCompanySize('Please select organization size');
      setErrorIndustry('Please select industry/sector');
      setErrorService('Please select service');

      //Set default valid state        
      setValidName(true);
      setValidEmail(true);
      setValidPhone(true);
      setValidCompanyName(true);
      setValidCompanySize(true);
      setValidIndustry(true);
      setValidService(true);

      //Check if name is entered
      if (!contactData.name) {
        flagerror = true;
        setValidName(false);
      }

      //Check if email is entered
      if (!contactData.email) {
        flagerror = true;
        setValidEmail(false);
      }

      //Check if email is valid
      const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      if (contactData.email && !emailRegex.test(contactData.email)) {
        //change error message
        setErrorEmail('Please enter valid email');
        setValidEmail(false);
        flagerror = true;
      }

      //Check if phone is entered
      if (!contactData.phone) {
        flagerror = true;
        setValidPhone(false);
      }

      //Check if phone is valid
      const phoneRegex = /^[0-9]{10}$/;
      if (contactData.phone && !phoneRegex.test(contactData.phone)) {
        flagerror = true;
        //change error message
        setErrorPhone('Please enter valid phone');
        setValidPhone(false);
      }

      //Check if company name is entered
      if (!contactData.company_name) {
        flagerror = true;
        setValidCompanyName(false);
      }

      //Check if company size is selected
      if (!contactData.organization_size) {
        flagerror = true;
        setValidCompanySize(false);
      }

      //Check if company size is selected and not default value
      if (contactData.organization_size === 'Select Organization Size') {
        flagerror = true;
        //change error message
        setErrorCompanySize('Please select organization size');
        setValidCompanySize(false);
      }

      //Check if industry is selected
      if (!contactData.industry) {
        flagerror = true;
        setValidIndustry(false);
      }

      //Check if industry is selected and not default value
      if (contactData.industry === 'Select Industry/Sector') {
        flagerror = true;
        //change error message
        setErrorIndustry('Please select industry/sector');
        setValidIndustry(false);
      }

      //Check if any service is selected
      let services = [];
      let service_mobile = document.getElementById('service_mobile').checked;
      let service_bi = document.getElementById('service_bi').checked;
      let service_ai = document.getElementById('service_ai').checked;
      let service_cloud = document.getElementById('service_cloud').checked;
      let service_custom = document.getElementById('service_custom').checked;
      let service_legacy = document.getElementById('service_legacy').checked;
      let service_website = document.getElementById('service_website').checked;
      let service_digital = document.getElementById('service_digital').checked;
      let service_maintenance = document.getElementById('service_maintenance').checked;
      let service_training = document.getElementById('service_training').checked;
      let service_others = document.getElementById('service_others').checked;
      let service_others_text = document.getElementById('service_others_text').value;

      if (service_mobile) services.push('Mobile App Development');
      if (service_bi) services.push('BI & Data Analytics');
      if (service_ai) services.push('AI & Automation');
      if (service_cloud) services.push('Cloud Apps & Infrastructure');
      if (service_custom) services.push('Custom Software Development');
      if (service_legacy) services.push('Legacy App Mordernization');
      if (service_website) services.push('Website & CMS Development');
      if (service_digital) services.push('Digital Marketing');
      if (service_maintenance) services.push('Maintenance & Support Services');
      if (service_training) services.push('Corporate Training');
      if (service_others) services.push(service_others_text);

      if (services.length === 0) {
        flagerror = true;
        //change error message
        setErrorService('Please select service');
        setValidService(false);
      }

      //check if other service is selected and text is not entered
      if (services.length > 0 && service_others && !service_others_text) {
        flagerror = true;
        //change error message
        setErrorService('Please enter other service name');
        setValidService(false);
      }

      //check if form is not valid
      if (flagerror) {
        setError(true);
        return false;
      }
      else {
        //set servicesvalue in contactData
        contactData.services = services.join(', ');
        setError(false);
        return true;
      }
      
    }
    catch (exceptions) {
      return false;
    }
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setError(false);

    //validate form
    const isValid = validateForm();
    
    if (!isValid) {
      return;
    }

    //Changing button text to Please Wait
    document.getElementById('submit-btn').innerHTML = 'Please Wait...';

    try {
      const response = await LeadService.add(contactData);

      console.log(response);
      Toastify({
        text: "Thank you for contacting us! We have received your query and will get back to you shortly.",
        gravity: "top",
        position: 'center',
        style: {
          background: "#F5E5BB",
          color: "#000000",
        }
      }).showToast();

      setContactData(initStateData);
      setIsResponseSubmitted(true);
      setError(false);
    }
    catch (error) {
      Toastify({
        text: error.message,
        gravity: "top",
        position: 'center',
        style: {
          background: "#F5E5BB",
          color: "#000000",
        }
      }).showToast();
    }

    document.getElementById('submit-btn').innerHTML = 'Submit';
  }

  const handleRedirect = () => {
    navigate('/');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {   
    //validate form if contactData is changed  
    if(contactData.name || contactData.email || contactData.phone || contactData.company_name || contactData.organization_size || contactData.industry || contactData.services)  
    {
      const isValid = validateForm();
      if (!isValid) {
        return;
      }
    }
  }, [contactData]);

  return (
    <div>
       <SEO
                title='Contact Us - Acompworld: Connect for Cutting-Edge IT Solutions'
                description="Get in touch with Acompworld's experts to discuss your digital transformation needs and start your journey towards cloud computing, generative AI, and more."
                keywords=''
            />
      <div className='bg__contact pb-4'>
        <NavbarHeader color="#F5E5BB" />
        <div className='padding_banner'>
          <div className='container-fluid   px-3'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='services_page_content mt-5'>
                  <h1 className='services_page_title'>Contact Us</h1>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='services_page_content mt-5'>
                  <p>Your business transformation starts with a conversation. Get in touch with us to learn how Acompworld can help you innovate, scale, and lead in the digital age. Fill out the form below and our team will reach out to you promptly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        {isResponseSubmitted ?
          <Row className="justify-content-md-center">
            <Col lg={9} md={10} sm={12}>
              <Form className='group-form text-center'>
                <Form.Group className="input-control mb-4">
                  <Form.Label>Thank you for contacting us!<br />We have received your query and will get back to you shortly.</Form.Label>
                </Form.Group>
                <Form.Group className="mt-2 input-control-btn" controlId="formBasicEmail">
                  <Button variant="primary" type="submit" className='submit-btn' onClick={handleRedirect}>
                    Back to home page
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          :
          <Row className="justify-content-md-center">
            <Col lg={9} md={10} sm={12}>
              <Form className='group-form'>
                {/*Name*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Your Name *</Form.Label>
                  <Form.Control type="text" name='name' value={contactData.name} onChange={handleChange} placeholder="" />
                  {error && (!validName) && <p className='error'>{errorName}</p>}
                </Form.Group>

                {/*Email*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control type="email" name='email' value={contactData.email} onChange={handleChange} placeholder="" />
                  {error && (!validEmail) && <p className='error'>{errorEmail}</p>}
                </Form.Group>

                {/*Contact Number*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Contact Number *</Form.Label>
                  <Row>
                    <Col xs={5} md={2} lg={2}>
                      <Form.Select className='form-control' name='phone_country' value={contactData.phone_country} onChange={handleChange}
                        aria-label="Default select example">
                        {countryList.map((country, index) => (
                          //show country code and country name in value like +91 India
                          <option key={index} value={country.country + ' (' + country.code + ')'}>{country.country} ({country.code})</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={7} md={10} lg={10}>
                      <Form.Control type="text" name='phone' value={contactData.phone} onChange={handleChange} placeholder="" maxLength={10} />
                      {error && (!validPhone) && <p className='error'>{errorPhone}</p>}
                    </Col>
                  </Row>
                </Form.Group>

                {/*Organization Name*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Organization Name *</Form.Label>
                  <Form.Control type="text" name='company_name' value={contactData.company_name} onChange={handleChange} placeholder="" />
                  {error && (!validCompanyName) && <p className='error'>{errorCompanyName}</p>}
                </Form.Group>

                {/*Organization Size*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Organization Size *</Form.Label>
                  {/* <Form.Control type="number" name='company_size' value={contactData.company_size} onChange={handleChange} placeholder="" /> */}
                  <select className='form-control' name='organization_size' onChange={(e) => { handleChange(e) }}>
                    <option>Select Organization Size</option>
                    {employeeRanges.map((employeeRange, index) => (
                      <option key={index} value={employeeRange}>{employeeRange}</option>
                    ))}
                  </select>
                  {error && (!validCompanySize) && <p className='error'>{errorCompanySize}</p>}
                </Form.Group>

                {/*Industry/Sector*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Industry/Sector *</Form.Label>
                  {/* <Form.Control type="number" name='company_size' value={contactData.company_size} onChange={handleChange} placeholder="" /> */}
                  <select className='form-control' name='industry' onChange={(e) => { handleChange(e) }}>
                    <option>Select Industry/Sector</option>
                    {industries.map((industry, index) => (
                      <option key={index} value={industry}>{industry}</option>
                    ))}
                  </select>
                  {error && (!validIndustry) && <p className='error'>{errorIndustry}</p>}
                </Form.Group>

                {/*Services*/}
                <Form.Group className="mb-4 input-control">
                  <Form.Label>Which services are you looking for? *</Form.Label>

                  {/*Checkboxes for services*/}
                  <div className='row formcheckrow'>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Mobile App Development" id="service_mobile" />
                      <label className="form-check-label" for="service_mobile">
                        Mobile App Development
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="BI & Data Analytics" id="service_bi" />
                      <label className="form-check-label" for="service_bi">
                        BI & Data Analytics
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="AI & Automation" id="service_ai" />
                      <label className="form-check-label" for="service_ai">
                        AI & Automation
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Cloud Apps & Infrastructure" id="service_cloud" />
                      <label className="form-check-label" for="service_cloud">
                        Cloud Apps & Infrastructure
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Custom Software Development" id="service_custom" />
                      <label className="form-check-label" for="service_custom">
                        Custom Software Development
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Legacy App Mordernization" id="service_legacy" />
                      <label className="form-check-label" for="service_legacy">
                        Legacy App Mordernization
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Website & CMS Development" id="service_website" />
                      <label className="form-check-label" for="service_website">
                        Website & CMS Development
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Digital Marketing" id="service_digital" />
                      <label className="form-check-label" for="service_digital">
                        Digital Marketing
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Maintenance & Support Services" id="service_maintenance" />
                      <label className="form-check-label" for="service_maintenance">
                        Maintenance & Support Services
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Corporate Training" id="service_training" />
                      <label className="form-check-label" for="service_training">
                        Corporate Training
                      </label>
                    </div>
                    <div className="form-check col-md-4">
                      <input className="form-check-input" type="checkbox" value="Others" id="service_others" />
                      <label className="form-check-label" for="service_others">
                        Others
                      </label>
                    </div>
                    <div className='form-check col-md-4'>
                      <input className="form-control" type="text" id="service_others_text" placeholder="Other Service Name" />
                    </div>
                  </div>

                  {error && (!validService) && <p className='error'>{errorService}</p>}
                </Form.Group>

                <Form.Group className="mb-4 input-control-btn" controlId="formBasicEmail">
                  <Button id="submit-btn" variant="primary" type="submit" className='submit-btn' onClick={handleSubmitForm}>
                    Submit
                  </Button>

                </Form.Group>
              </Form>
            </Col>
          </Row>
        }

      </Container>


      <div class="Locations">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="heading text-center">
                <h2 class="">Locations</h2>
              </div>
            </div>
          </div>
          <div class="Locations_box">
            <div class="row">
              <div class="col-md-4">
                <div class="Locations_card">
                  <div class="Locations_footer">
                    <h4 class="Locations_title">Delhi-NCR
                    </h4>
                    <p>
                      F-7, Serenia, IHDP Business Park,<br /> Sector 127, Noida (UP) 201303
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="Locations_card">
                  <div class="Locations_footer">
                    <h4 class="Locations_title">Bhopal
                    </h4>
                    <p>
                    S.PACE, E-3/114, Arera Colony,<br/>10 Number, Bhopal (MP) 462016
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="Locations_card">
                  <div class="Locations_footer">
                    <h4 class="Locations_title">Singrauli
                    </h4>
                    <p>Aptech Campus, Govt College Road,<br/>Waidhan, Singrauli (MP) 486886</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='default'>
        <Footer color="#00000" />
      </div>
    </div>
  )
}

export default Contact
