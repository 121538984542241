import React from 'react'
import NavbarHeader from './navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SEO from '../seo';

const CloudApplications = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <div>
            <SEO
  title='Cloud Applications - Acompworld: Secure, Scalable & Cost-Effective Solutions'
  description="Leverage secure, scalable, and cost-effective cloud applications to optimize business performance with Acompworld's cloud computing solutions."
  keywords=''
/>
            <div className='bg__cloudapplications pb-4'>
                <NavbarHeader color="#CEEEFD" />
                <div className='padding_banner'>
                    <div className='container-fluid marging-d-pading'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>Cloud Applications</h1>
                                    <h2>Powering Your Digital Transformation</h2>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Unlock your business's full potential with our cloud application solutions. Optimize operations, achieve scalability, and enhance performance across diverse industries. Experience seamless digital transformation with secure data management and streamlined workflows. Propel your business to new heights today!</p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/cloud.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>
            <section className="mt-5">

                <div className='container-fluid px-5'>
                    <div className='row' >
                        <div className='col-lg-3 col-md-6'>
                            <div className='service__card__wrapper'>
                                <p>Collaboration without Boundaries: Foster Teamwork with Cloud-based Productivity Tools.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='service__card__wrapper'>
                                <p>Natural Language Processing: Enable Voice-Activated Software Control and Interaction.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='service__card__wrapper'>
                                <p>Scale Your Business to New Heights: Harness Cloud Applications for Rapid Growth.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='service__card__wrapper'>
                                <p>Flexible Accessibility: Access Your Business Applications Anytime, Anywhere.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="work__line">
                <hr className='line__gray' />
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content"  >
                                <h3>Why is our service the best?</h3>
                                <p>
                                    With Acompworld, you gain access to a team of cloud experts who develop and deploy tailored cloud applications to meet your unique business needs. Our solutions offer scalability, ensuring flexibility and cost-effectiveness as your business grows.
                                </p>

                                <p>
                                    We prioritize data security, seamless integration, and high-performance applications. By leveraging cloud technologies, we provide innovative solutions that reduce costs and keep your business ahead.
                                </p>

                                <p>
                                    Partner with Acompworld to drive your digital transformation and unlock the full potential of cloud computing.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-1 displaynone-div'>

                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/bi-card.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="work mt-100">
                <div className="container-fluid">
                    <div className='col-lg-1 col-md-3 col-3'>
                        <div className='work__image'>
                            <img src='/images/abg.png' className='' alt='' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12 mt-5">
                            <div className="work-box">
                                <div className="work-title">
                                    <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='default'>
                <div className="container-fluid">
                    <div className='footer__heading'>
                        <h2 class="center">Have a project idea? Let's come together to build something great</h2>
                    </div>
                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn__footer'>Talk to an expert</Link>
                    </div>

                </div>
                <Footer color="#00000" />
            </div>
        </div>
    )
}

export default CloudApplications
