import React from "react";

const Logo = ({color})=>(
    <svg version="1.1" id="Layer_1" width="205px" height="" 
    viewBox="0 0 1265.527 323.575" enable-background="new 0 0 1265.527 323.575">
<text transform="matrix(1 0 0 1 327.5264 -310.5332)" font-family="'GilmerBold'" font-size="158.2515">acompworld</text>

<g>
	<path fill={color} d="M104.323,74.748c-16.438,0.017-30.734,14.623-29.514,29.966c0.894,11.278,6.078,19.847,16.422,24.847
		c11.162,5.396,21.981,5.335,32.26-2.4c5.283-3.97,10.257-8.283,14.75-13.106c3.999-4.293,8.712-6.926,14.559-7.466
		c2.47-0.231,4.949-0.395,7.425-0.563c11.892-0.81,22.618,1.062,30.655,11.278c4.121,5.235,9.016,9.987,14.907,13.388
		c3.284,1.898,6.695,4.498,10.689,1.908c4.043-2.623,4.356-6.959,4.427-11.267c0.012-0.61-0.117-1.232-0.204-1.85
		c-1.602-11.382-6.511-21.333-13.467-30.304c-4.132-5.329-8.826-10.138-15.031-13.128c-4.752-2.29-6.626-1.614-8.889,3.056
		c-1.749,3.613-2.666,7.497-3.582,11.358c-1.024,4.316-3.692,6.515-7.884,7.353c-6.29,1.261-12.595,0.939-18.925,0.482
		c-7.586-0.552-14.022-3.632-20.028-8.184c-5.241-3.969-10.815-7.522-16.364-11.063C112.755,76.641,108.811,74.44,104.323,74.748z"
		/>
	<path fill={color} d="M232.287,200.639c-0.572-6.341-1.144-12.489-2.556-18.531c-2.194-9.36-6.595-12.27-16.153-10.712
		c-5.984,0.985-10.878,3.398-14.781,8.386c-5.511,7.047-11.601,13.634-19.283,18.483c-8.96,5.652-15.667,4.332-24.375-3.25
		c-7.493-6.508-15.086-12.937-22.925-19.029c-3.694-2.87-8.561-3.208-13.102-3.845c-11.417-1.609-22.786-1.154-34.054,1.313
		c-3.702,0.805-6.931,2.474-9.883,4.938c-6.673,5.563-13.38,11.106-20.309,16.354c-14.557,11.021-32.654,7.149-41.158-9.042
		c-7.704-14.656-6.231-32.97,6.483-44.96c4.915-4.635,10.801-6.945,17.369-7.779c3.995-0.514,7.586,0.495,10.918,2.801
		c7.973,5.516,15.706,11.381,23.078,17.652c6.027,5.13,12.643,8.062,20.467,8.777c10.179,0.933,20.294,0.914,30.465-0.319
		c8.617-1.034,13.938-7.067,20.033-12.085c5.727-4.718,10.86-10.136,17.205-14.145c6.597-4.163,12.932-3.508,19.262,0.367
		c7.968,4.879,14.24,11.656,19.909,18.892c6.282,8.006,14.526,8.752,23.494,7.421c3.159-0.459,5.264-2.496,6.128-5.776
		c1.394-5.292,2.58-10.608,2.793-16.104c0.084-2.22,0.222-4.42,1.033-6.688C238.675,142.876,238.672,194.358,232.287,200.639z"/>
	<path fill={color} d="M105.279,204.325c6.169-0.374,10.623,1.341,14.777,4.028c7.555,4.895,13.959,11.146,20.34,17.395
		c2.207,2.168,4.646,3.648,7.637,4.354c9.323,2.195,18.651,2.465,28.024,0.316c3.755-0.864,6.789-2.93,9.658-5.367
		c6.719-5.711,13.466-11.376,20.224-17.036c1.139-0.961,2.342-1.863,3.621-2.612c3.608-2.12,7.111-1.106,9.087,2.632
		c1.759,3.315,2.001,6.913,1.386,10.521c-2.806,16.586-11.547,29.75-24.26,40.252c-8.103,6.698-12.055,5.17-15.049-4.843
		c-0.426-1.427-0.769-2.877-1.148-4.318c-2.146-8.158-3.731-9.71-12.151-10.455c-6.559-0.587-13.143-0.457-19.651,0.898
		c-4.189,0.865-7.982,2.422-11.488,4.997c-5.703,4.18-11.716,7.937-17.483,12.039c-19.484,13.856-43.077-1.518-44.107-23.07
		c-0.534-11.138,7.502-22.894,18.978-27.299C97.756,205.19,101.964,204.148,105.279,204.325z"/>
	<path fill={color} d="M162.074,67.486c-0.053,5.093-4.55,8.54-10.418,7.995c-14.378-1.343-28.891-15.729-30.338-30.083
		c-0.521-5.139,2.632-8.502,7.54-7.315c13.609,3.301,24.345,10.771,31.492,23.004C161.485,63.02,162.081,65.195,162.074,67.486z"/>
	<path fill={color} d="M120.839,290.558c1.46-9.758,7.39-18.075,16.867-23.917c4.458-2.743,9.212-4.93,14.667-4.751
		c6.912,0.226,10.378,5.097,8.264,11.636c-0.886,2.743-2.296,5.223-4.167,7.413c-7.439,8.734-16.508,15.033-27.753,17.686
		C123.196,299.927,120.716,297.601,120.839,290.558z"/>
</g>
<g>
	<path fill={color} d="M322.343,227.004c-11.423,0-20.955-4.085-28.681-12.362c-7.72-8.279-11.578-18.52-11.578-30.734
		c0-12.367,3.858-22.692,11.578-30.881c7.727-8.197,17.337-12.298,28.763-12.298c11.187,0,19.934,4.022,26.24,12.137v-9.849h21.509
		v81.627h-21.509v-10.002c-6.306,8.191-15.053,12.29-26.24,12.29L322.343,227.004z M326.047,207.779
		c6.542,0,12.059-2.271,16.549-6.848c4.49-4.573,6.698-10.324,6.698-17.337c0-6.859-2.208-12.528-6.698-17.025
		c-4.49-4.482-10.007-6.771-16.549-6.771c-6.695,0-12.21,2.207-16.544,6.695c-4.336,4.5-6.462,10.243-6.462,17.263
		c0,7.086,2.126,12.915,6.462,17.409c4.333,4.486,9.849,6.774,16.544,6.774V207.779z"/>
	<path fill={color} d="M429.114,227.167c-12.53,0-22.85-4.176-31.042-12.454c-8.199-8.264-12.295-18.591-12.295-30.883
		c0-12.29,4.096-22.533,12.295-30.729c8.192-8.192,18.512-12.293,30.884-12.293c10.478,0,19.304,2.761,26.472,8.274
		c7.173,5.518,11.666,11.662,13.477,18.518l-19.857,5.67c-0.944-3.705-3.231-6.849-6.854-9.533
		c-3.625-2.677-7.958-4.019-13.081-4.019c-6.539,0-11.901,2.369-15.994,7.015c-4.098,4.645-6.145,10.324-6.145,17.022
		c0,6.537,2.046,12.205,6.221,17.016c4.178,4.808,9.538,7.168,16.077,7.168c5.04,0,9.373-1.255,13.077-3.863
		c3.705-2.595,5.99-5.665,6.935-9.22l19.855,5.519c-1.811,7.017-6.223,13.156-13.313,18.518c-7.094,5.367-15.838,8.039-26.321,8.039
		L429.114,227.167z"/>
	<path fill={color} d="M523.193,226.932c-12.604,0-23.008-4.099-31.282-12.367c-8.273-8.278-12.367-18.517-12.367-30.809
		c0-12.29,4.175-22.541,12.446-30.73c8.278-8.197,18.676-12.298,31.282-12.298c12.609,0,23.009,4.101,31.283,12.298
		c8.273,8.189,12.369,18.44,12.369,30.73c0,12.292-4.096,22.53-12.369,30.809c-8.273,8.269-18.673,12.367-31.203,12.367H523.193z
		 M539.74,200.857c4.257-4.499,6.383-10.166,6.383-17.027c0-6.858-2.126-12.525-6.383-17.014c-4.254-4.493-9.767-6.699-16.547-6.699
		c-6.774,0-12.292,2.206-16.548,6.699c-4.252,4.489-6.381,10.166-6.381,17.014c0,6.861,2.129,12.528,6.381,17.027
		c4.256,4.487,9.774,6.688,16.548,6.688C529.973,207.545,535.486,205.345,539.74,200.857z"/>
	<path fill={color} d="M680.548,140.651c9.845,0,17.729,3.073,23.558,9.296c5.831,6.228,8.749,14.892,8.749,26.08v48.545h-21.355
		v-44.048c0-13.32-5.198-20.015-15.602-20.015c-5.591,0-9.849,1.968-12.844,5.911c-2.997,3.939-4.488,9.458-4.488,16.626v41.597
		h-21.515v-44.045c0-13.311-5.119-20.011-15.287-20.011c-5.749,0-10.085,2.05-13.076,6.068c-2.994,4.015-4.492,9.694-4.492,17.027
		v40.961h-21.274v-81.627h21.274v9.692c6.145-7.958,14.265-11.975,24.272-11.975c11.108,0,19.302,4.096,24.581,12.292
		c6.459-8.197,15.681-12.292,27.579-12.292L680.548,140.651z"/>
	<path fill={color} d="M780.62,140.728c11.425,0,20.958,4.101,28.681,12.293c7.72,8.194,11.584,18.445,11.584,30.809
		c0,12.218-3.864,22.456-11.584,30.734c-7.723,8.269-17.256,12.367-28.527,12.367c-11.107,0-19.934-4.099-26.476-12.29v43.879
		h-21.271V143.016h21.271v9.849c6.468-8.115,15.288-12.137,26.476-12.137H780.62L780.62,140.728z M776.915,208.016
		c6.698,0,12.214-2.278,16.548-6.774c4.333-4.486,6.463-10.314,6.463-17.411c0-7.008-2.13-12.764-6.463-17.261
		c-4.334-4.482-9.85-6.69-16.548-6.69c-6.538,0-12.056,2.287-16.546,6.776c-4.496,4.486-6.697,10.167-6.697,17.017
		c0,7.02,2.207,12.764,6.697,17.335c4.49,4.572,10.006,6.859,16.546,6.859V208.016z"/>
	<path fill={color} d="M952.942,143.016l-28.285,81.627h-19.859l-16.783-51.448l-16.781,51.448h-19.937l-28.128-81.627h22.137
		l16.942,54.684l16.787-54.684h17.885l16.939,54.684l16.94-54.684H952.942z"/>
	<path fill={color} d="M999.829,226.932c-12.61,0-23.007-4.099-31.283-12.367c-8.273-8.278-12.368-18.517-12.368-30.809
		c0-12.29,4.175-22.541,12.447-30.73c8.271-8.197,18.675-12.298,31.281-12.298c12.608,0,23.009,4.101,31.282,12.298
		c8.272,8.189,12.371,18.44,12.371,30.73c0,12.292-4.099,22.53-12.371,30.809c-8.273,8.269-18.674,12.367-31.203,12.367H999.829z
		 M1016.375,200.857c4.255-4.499,6.38-10.166,6.38-17.027c0-6.858-2.125-12.525-6.38-17.014c-4.256-4.493-9.775-6.699-16.55-6.699
		c-6.778,0-12.288,2.206-16.546,6.699c-4.257,4.489-6.384,10.166-6.384,17.014c0,6.861,2.127,12.528,6.384,17.027
		c4.258,4.487,9.768,6.688,16.546,6.688C1006.6,207.545,1012.119,205.345,1016.375,200.857z"/>
	<path fill={color} d="M1080.828,155.15c5.356-10.556,14.339-14.969,26.948-13.24v20.017c-8.905-1.417-15.603,0.078-20.093,4.494
		c-4.49,4.41-6.779,11.978-6.779,22.691v35.531h-21.271v-81.627h21.271v12.054L1080.828,155.15z"/>
	<path fill={color} d="M1121.724,106.849h21.275v117.794h-21.275V106.849z"/>
	<path fill={color} d="M1247.008,106.923V224.73h-21.513v-10.018c-6.38,8.206-15.128,12.291-26.235,12.291
		c-11.428,0-20.962-4.085-28.685-12.362c-7.722-8.279-11.58-18.52-11.58-30.734c0-12.367,3.858-22.692,11.58-30.881
		c7.723-8.197,17.336-12.298,28.761-12.298c11.188,0,19.937,4.022,26.238,12.137v-46.016L1247.008,106.923z M1202.959,207.779
		c6.54,0,12.06-2.271,16.55-6.848c4.491-4.573,6.697-10.324,6.697-17.337c0-6.859-2.206-12.528-6.697-17.025
		c-4.49-4.482-10.006-6.771-16.55-6.771c-6.695,0-12.214,2.207-16.546,6.695c-4.335,4.5-6.461,10.243-6.461,17.263
		c0,7.086,2.126,12.915,6.461,17.409c4.332,4.486,9.849,6.774,16.546,6.774V207.779z"/>
</g>
</svg>
)

export default Logo;