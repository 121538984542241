import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NavDropdown from 'react-bootstrap/NavDropdown';
//importing useState
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa'; // Import your desired dropdown icon from a library like react-icons
import { FaBars, FaTimes } from 'react-icons/fa';


const Header = ({ color }) => {


  //use navigate to redirect to another page
  const navigate = useNavigate();

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const closeDropdown = () => {
    setShowDropdown1(false);
    setShowDropdown2(false);
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navClass = scrolled ? 'scroll-nav navbar-scrolled' : 'scroll-nav'
  const [showCollapse, setShowCollapse] = useState(false);
  const [isClicked, setIsClickeded] = useState(false);

  const handleToggle = () => {
    setShowCollapse(!showCollapse);
    setIsClickeded(!isClicked);
  };

  const toenterpriseMoblity = () => {
    navigate('/services/enterprise-mobility');
  }
  const toCloudApplication = () => {
    navigate('/services/cloud-applications');
  }
  const toBiAnalytics = () => {
    navigate('/services/bi-analytics');
  }
  const toAiAutomation = () => {
    navigate('/services/ai-automation');
  }





  return (


    <div>

      <Navbar fixed="top" className={navClass}>
        <Container fluid className=''>
          <Link to='/' className='nav_brand navbar-brand'><img src='/images/Logo-White.svg' className='logo_brand_images' /></Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          >
            {isClicked ? (
              <FaTimes color={"#FFFFFF"} />
            ) : (
              <FaBars color={"#FFFFFF"} />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className='bg-blck-web'>
            <Nav className="">
              <Link to=''>

                <NavDropdown title="services"
                  id="collasible-nav-dropdown"
                  show={showDropdown1}
                  onClick={toggleDropdown1}
                  onSelect={closeDropdown}
                  className='nav__link'

                >
                  <NavDropdown.Item className='dropdown__navbar' onClick={toenterpriseMoblity}>Enterprise Mobility</NavDropdown.Item>
                  <NavDropdown.Item className='dropdown__navbar' onClick={toCloudApplication}>Cloud Application</NavDropdown.Item>

                  <NavDropdown.Item className='dropdown__navbar' onClick={toBiAnalytics}>BI & Analytics</NavDropdown.Item>
                  <NavDropdown.Item className='dropdown__navbar' onClick={toAiAutomation}>AI & Automation</NavDropdown.Item>

                </NavDropdown>
              </Link>
              <Link to="/work" className='nav__link nav-link'>
                Work
              </Link>

              <Link to=''>
                <NavDropdown title="Company"
                  id="collasible-nav-dropdown"
                  show={showDropdown2}
                  onClick={toggleDropdown2}
                  onSelect={closeDropdown}
                  className={showDropdown2 ? 'nav__link show' : 'nav__link'}
                  dropdownIcon={<FaAngleDown />}
                >

                  <Link to='/about' className='dropdown__navbar dropdown-item'>About</Link>
                  <Link to='/careers' className='dropdown__navbar dropdown-item'>Careers</Link>
                </NavDropdown>
              </Link>

              <Link to="/blogs" className='nav__link nav-link'>
                Insights
              </Link>


            </Nav>
            <Nav className='ms-auto'>
              <Nav.Link className=' nav__link'><Link to="/contact" className=' nav__link'>Contact</Link></Nav.Link>

              <a href="/schedule-call" className='btn__link nav-link'>
                Schedule a call
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container >
      </Navbar>

    </div>
  )
}

export default Header
