//Create api handler for all api calls
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.acompworld.com/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

//Call an asyncrosous get request to the api
export const get = async (url) => {
    try {
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

//Call an asyncrosous post request to the api
export const post = async (url, data) => {
    try {
        const response = await api.post(url, data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};


export default api;

