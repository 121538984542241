import api from "./api";
import APIError from "../exceptions/APIError";

class CareerServices {
    async getAll() {
        try {
            const response = await api.get("/jobMaster/active");
            return response.data;
        } catch (error) {
            console.log("res", error.response);
            if (error.response && error.response.status !== 500)
                throw APIError.badRequest(error.response.data.message);
            throw APIError.internal(error.message);
        }
    }

    async getByJobId(jobId) {
        if (!jobId)
            throw APIError.badRequest("No job id provided");

        try {
            const response = await api.get(`/jobMaster/${jobId}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status !== 500)
                throw APIError.badRequest(error.response.data.message);
            throw APIError.internal(error.message);
        }
    }

}

export default new CareerServices();