import api from "./api";
import APIError from "../exceptions/APIError";
import { toast } from 'react-toastify';

class UploadServices {
    async UploadImageFor(formdata) {
        try {
            const headers = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': localStorage.getItem("token")
                }
            }

            const response = await api.post(`uploads`, formdata, headers).then((response) => {
                return response
            }
            ).catch(error => {
                return toast.error(error)

            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }


    }
}

export default new UploadServices();