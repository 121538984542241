import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './logo'

const Footer = ({color}) => {
  return (
    <div>
          <footer className="footer px-5">
            <div className="container-fluid">
                  
                    <div className='row mt-5'>
                        <div className='col-lg-3 col-md-6 col-sm-12 footer-mobile-b'>
                            <div className='footer__brand'>
                                <h3><Link to="/"><Logo color={color}/> </Link></h3>
                                </div>

                            </div>
                        <div className='col-lg col-md-4 col-6'>
                            <div className='footer__content'>
                                <h3>Company</h3>
                                <ul>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/blogs">Insights</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>

                                </ul>

                                </div>
                                </div>
                                <div className='col-lg col-md-4 col-6'>
                            <div className='footer__content'>
                                <h3>Services</h3>
                                <ul>
                                    <li><Link to="/services/enterprise-mobility">Enterprise Mobility</Link></li>
                                    <li><Link to="/services/cloud-applications">Cloud Applications</Link></li>
                                    <li><Link to="/services/bi-analytics">BI & Analytics</Link></li>
                                    <li><Link to="/services/ai-automation">AI & Automation</Link></li>

                                </ul>

                                </div>
                                </div>
                                <div className='col-lg col-md-4 col-6'>
                            <div className='footer__content'>
                                <h3>Channels</h3>
                                <ul>
                                    <li><Link to="https://www.facebook.com/acompworld?_rdr=p" target='_blank'>Facebook</Link> </li> 
                                    <li><Link to="https://twitter.com/acompworld " target='_blank'>Twitter</Link></li>
                                    <li><Link to="https://www.linkedin.com/company/acompworld" target='_blank'>Linkedin</Link></li>
                                   

                                </ul>

                                </div>
                                </div>
                                <div className='col-lg col-md-6 col-6'>
                            <div className='footer__content'>
                                <h3>Acompworld © 2024 </h3>
                                <ul>
                                    <li> <Link to="/privacy-policy" >Privacy Policy</Link> </li>                                 
                                </ul>

                                </div>
                                </div>
                    </div>
                </div>
            </footer>
    </div>
  )
}

export default Footer
