import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import {useState, useEffect} from 'react';
import AllBlog from './all-blog';


const BlogTittle = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
        <div className='bg__blogtittlele pb-4'>
        <NavbarHeader color="#FFEBDC"/>
                 <div className='padding_banner marging-d-pading'>
                 <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6 pe-5'>
                        <div className='services_page_content mt-5 mb-3'>
                            <h1 className='services_page_title_blog'>Blog Title #01</h1>
                          </div>
                          <div className='services_page_content'>
                                <p className='p-0'>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.</p>
                                </div>
                          </div>

                          <div className='col-md-6 mb-4'>
                          <article className='service__banner__image mt-50'>
                              <picture>
                                  <img src='/images/blog-tittle.webp' alt='' className='' width="100%"/>
                                </picture>
                          </article>
                            </div>
                          </div>

                          
                         </div>
                 </div>
                    </div>

     
            <section className="mt-5 px-5">
                <Container fluid>
                    <Row>
                         <Col lg="3">
                            <p className='pt-2 date-tittle'>24th May, 2023  </p>
                         </Col>
                     
                    </Row>
                </Container>
        
             
            </section>
              


         
      

         

                     

          
              



            

                   

            <section className="work__line px-5">
             
        
                <hr className='m-0' />
            </section>


            <Container fluid>
                    <Row className="justify-content-md-center">
                         <Col lg="9">
                             <div className='blog_tittle_conten_base'>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                </p>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 </p>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                     </p>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.. </p>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.. </p>

                             </div>

                             <div className='images mt-5'>
                                 <img src='/images/globaltab.webp' alt='' className='' width="100%"/>
                                  <div className='images-caption'>Image caption here</div> 
                                  <div className='blog_tittle_conten_base mt-5'>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                 An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services..
                                </p>
                               
                                 
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.. </p>
                                 <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services. </p>

                             </div>
                             </div>
                         </Col>
                     
                    </Row>
                </Container>

                <AllBlog />
                            
           
      
       <div className='default mt-5'>
  
           
      <Footer color="#00000"/> 
       </div>
    </div>
  )
}

export default BlogTittle
