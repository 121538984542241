import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import { Link } from 'react-router-dom';
//importing useEffect 
import { useState, useEffect } from 'react';
import SEO from './seo';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div>
      <SEO
  title='About Us - Acompworld: Your Trusted Partner for Digital Transformation'
  description='Learn about Acompworld, a leading provider of innovative IT solutions, helping enterprises succeed with cloud, generative AI, automation, and analytics.'
  keywords=''
/>
      <div className='bg__about pb-4'>
        <NavbarHeader color="#FCEDDA" />
        <div className='padding_banner marging-d-pading'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='services_page_content my-5'>
                  <h1 className='services_page_title'>About</h1>
                </div>
              </div>

              <div className='col-md-6 mb-4'>
                <div className='services_page_content my-5'>
                  <p>From a humble beginning in 2012, to becoming preferred software engineering partner for world-class brands, we have come a long way, guided by our vision “to be a globally recognized tech company driving positive transformation in organizations and people's lives”</p>
                </div>
              </div>
            </div>

            <article className='service__banner__image'>
              <picture>
                <img src='/images/about-banner-image.webp' alt='' className='' width="100%" />
              </picture>
            </article>
          </div>
        </div>
      </div>


      <section className='best__service mt-100'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className="best__service_content">
                <h3>What makes us the preferred digital transformation partner for fortune 500 clients</h3>
                <p>360º Approach: From ideation to delivery and post-launch support, we offer a comprehensive lifecycle approach to enterprise applications.</p>
                <p>Client-Centric Model: Our boutique structure guarantees personalized, tailored solutions, fostering long-term partnerships.</p>
                <p>Domain Experts: Our unmatched domain expertise and deep knowledge of niche technologies set us apart.</p>
                <p>Accelerated Development: High-level expertise and solution accelerators ensure swift product rollouts and customizations.</p>
                <p>‘A’ Class Team: We boast a top-tier team, leveraging their exceptional software engineering skills for client success.</p>
                <p>Great ROI: Partnering with us means co-creating cost-effective solutions optimized for your business, ensuring a rewarding investment.</p>
              </div>
            </div>
            <div className='col-lg-1 displaynone-div'></div>

            <div className='col-lg-5 col-md-6 '>
              <div className="best__service__image mt-200">
                <img src='/images/aboutuscard.webp' className='' width="100%" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="work__line">
        <hr className='line__gray' />
      </section>

      <section className="culture mt-100">
        <div className='container-fluid px-5 mb-5 pb-3'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className="about-bottom">
                <h3>Fostering a Culture of <br /> Creativity and Teamwork</h3>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className="about-bottom">
                <p>   At Acompworld, we cultivate a culture of creativity and teamwork. Our passionate team collaborates to push boundaries, inspire breakthrough solutions, and deliver exceptional results. Together, we drive innovation that transforms businesses and shapes the future of technology.</p>
              </div>
            </div>
          </div>
        </div>
        <img src='/images/banner/about-down-banner-image.webp' width="100%" className="" />
      </section>

      <div className='default mt-100'>
        <div className='container-fluid'>
          <div className='footer__heading'>
            <h2 className='center'>Feeling inspired? Join us in shaping the future</h2>
          </div>
          <div className='footer__btn'>
            <Link to="/careers" role='button' className='btn__footer'>View Opportunities</Link>
          </div>

        </div>
        <Footer color="#00000" />
      </div>

    </div>
  )
}

export default AboutUs
