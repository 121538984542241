import React, { useEffect, useRef, useState } from 'react';
import Header from './header'
import Footer from './footer'
import { Link } from 'react-router-dom'
import Slider from './carousel/carousel'
import { Player, ControlBar } from 'video-react';

import SEO from './seo';


function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        // Play the video when the component mounts
        video.play().catch(error => {
            // Handle autoplay error (e.g., user interaction required)
            console.error('Autoplay error:', error);
        });

        // Add event listener to restart the video when it ends
        video.addEventListener('ended', () => {
            video.currentTime = 0;
            video.play();
        });

        // Clean up event listener and pause video when the component unmounts
        return () => {
            video.removeEventListener('ended', () => {
                video.currentTime = 0;
                video.play();
            });
            video.pause();
        };
    }, []);

    const [visibleSections, setVisibleSections] = useState([]);


    return (
        <>
        
        <SEO
  title='Acompworld: Leading Digital Transformation with Cloud, AI & Automation Solutions'
  description='Acompworld accelerates your digital transformation journey with cutting-edge AI, cloud computing, business analytics, and automation solutions.'
  keywords=''
/>
       
        <div className='header__index'>
            <main className='main__index__bgdark'>
                <Header />
                <section className="hero">
                    <video ref={videoRef} autoPlay loop muted>
                        <source src="/images/digital-video.webm" type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                    <article className='bg-light-dark pt-5'>

                        <div className="caption-point">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="hero-content pt-5 my-5">
                                        <h1 className=''>Innovate <i class="fa-solid fa-circle"></i> Transform <i class="fa-solid fa-circle"></i> Excel</h1>
                                        < p className=''>Embrace the Power of Generative AI <br />for Next-Gen Digital Transformation</p>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="hero-image">

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className='btn__apply__now'>
                                    <Link to="/services/ai-automation" className='learn_more'>learn more   <img src='/images/arrow-r.png' /> </Link>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>


                {/* About Us */}
                <section className="who-we-are">
                    <div className="container-fluid">
                        <hr className='line__throught mb-60' />
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="service-title">
                                        <h3>Who are we?</h3>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 mt-2'>
                                <div className="service-box">
                                    <p>At Acompworld, we're passionate professionals, leveraging technology to transform businesses and unlock their full potential. With deep process knowledge and optimal solutions, we're committed to driving growth and being your trusted partner in success.</p>
                                </div>
                                <Link to="/about" className='btn btn__discover'>Discover Our Journey  <img src='/images/arrow-r.png' /> </Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Our Services */}
                <section className="margin-130">
                    <img src='/images/image-section.webp' className='' alt='' width="100%" />
                </section>
                <section className="services mt-60">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                                <div className="service-box">
                                    <div className="service-title">
                                        <h3>Services</h3>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-5 mt-2'>
                                <div className="service-box">
                                    <p>Empowering businesses with comprehensive technology solutions. From cloud expertise to AI-driven automation, we streamline operations, enhance customer experiences, and leverage data for informed decision-making. Unlock your full potential with us.</p>
                                </div>
                                <Link to="/services/enterprise-mobility" href="#" className='btn btn__discover'>discover our services   <img src='/images/arrow-r.png' /> </Link>
                            </div>
                        </div>
                        <div className="row  margin-130">
                            <div className='col-lg-3 col-md-6 col-6'>
                                <Link to="/services/enterprise-mobility">
                                    <div className='service__box__image hover__effect__scale'>
                                        <img src='/images/card/cardd-01.png' className='' alt='' width="100%" />
                                        <div className='services__box__caption'>
                                            Enterprise <br /> Mobility
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-3 col-md-6 col-6'>
                                <Link to="/services/cloud-applications">
                                    <div className='service__box__image hover__effect__scale'>
                                        <img src='/images/card/card-04.webp' className='' alt='' width="100%" />
                                        <div className='services__box__caption card__c01'>
                                            Cloud  <br /> Applications
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-3 col-md-6 col-6'>
                                <Link to="/services/bi-analytics">

                                    <div className='service__box__image hover__effect__scale'>
                                        <img src='/images/card/card-03.webp' className='' alt='' width="100%" />
                                        <div className='services__box__caption card__c02'>
                                            BI & <br /> Analytics
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-3 col-md-6 col-6 '>
                                <Link to="/services/ai-automation">
                                    <div className='service__box__image hover__effect__scale'>
                                        <img src='/images/card/card-02.webp' className='' alt='' width="100%" />
                                        <div className='services__box__caption card__c03'>
                                            AI & <br /> Automation
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="marginrem2">
                    <div className="container-fluid">
                        <Slider />
                    </div>
                </section>

                {/* Insights */}
                <section className='blog-home-r mt-80' >
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                                <div className="service-box">
                                    <div className="service-title">
                                        <h3>Insights</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 mt-2'>
                                <div className="service-box">
                                    <p>Trusted by industry leaders like Exim Banks, NTPC, Indian Railways, and Aditya Birla Group, we drive impactful digital transformation. Our expertise and collaborations showcase our ability to deliver innovation and success.</p>
                                </div>
                                <Link to="/blogs" className='btn btn__discover'>Discover More <img src='/images/arrow-r.png' /></Link>
                            </div>
                        </div>
                        <div className="row margin-130" >
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/predictive-maintenance-energy">
                         <div className='service__box__image'>
                              <img src='/images/card/harnessing-predictive-card.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p> Harnessing Predictive Maintenance and Deep Learning for the Energy Sector</p>
                                  <span>23rd August, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/understanding-ai-hallucinations">
                         <div className='service__box__image'>
                              <img src='/images/card/understanding-hallucinations-card.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p>Understanding AI Hallucinations: Causes, Implications, and Prevention</p>
                                  <span>6th August, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/smaller-models-bigger-impact">
                         <div className='service__box__image'>
                              <img src='/images/card/smaller-models-card.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p>Smaller Models, Bigger Impact: The Rise of Efficient AI</p>
                                  <span>20th June, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/generative-ai-deliverin">
                         <div className='service__box__image'>
                              <img src='/images/card/generative-ai-delivering.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p> Is Generative AI Delivering on Its Promise in India?</p>
                                  <span>29th May, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        {/* <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/revolutionizing-business-strategy">
                         <div className='service__box__image'>
                              <img src='/images/card/revolutionizing-business-strategy.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p>  Industry 5.0: Revolutionizing Business Strategy for a Human-Centric, Resilient, and Sustainable Future</p>
                                  <span>18th May, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>

                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/multimodal-ai-digital-transformation">
                         <div className='service__box__image'>
                              <img src='/images/card/multimodal-ai.webp' className='' alt='' width="100%"/>
                               <div className=''>
                                  <div className='tittle__box__insight'>
                                  <p>  Multimodal AI: The New Frontier in Digital Transformation</p>
                                  <span>10th May, 2024</span>
                                  </div>
                               </div>
                            </div> 
                        </Link>  
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 blog-box'>
                         <Link to="/blogs/automation-can-supercharge">
                         <div className='service__box__image'>
                              <img src='/images/card/card-robots.webp' className='' alt='' width="100%"/>
                               <div className='tittle__box__insight'>
                               <p>Robots Don't Steal Jobs, They Amplify Them: How AI Automation Can Supercharge Your Business</p>
                                  <span> 26th April, 2024</span>
                               </div>
                            </div> 
                        </Link>  
                        </div> */}
                        
                            
                        </div>
                    </div>
                </section>

                <section className="work__line mt-50">
                    <hr className='line__throught' />
                </section>

                {/* Call to Action */}

                <div id="section7" className="container-fluid mt-50" >
                    <div className='footer__heading center'>
                        <h2>Embark on Your Digital Transformation Journey Today! </h2>
                    </div>
                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn btn__footer'>Talk to an expert</Link>
                    </div>
                </div>

                <div className='aco__footer_p'>
                    <Footer color="white" />
                </div>
            </main>

        </div>
        </>
    )
}

export default Home
