import React from 'react'
import NavbarHeader from './services/navbar'
import Footer from './footer'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Height = {
    height: '40vh'
    // Add more CSS properties as needed
  };
  return (
    <div>
      <div className='bg__contact pb-4'>
        <NavbarHeader color="#F5E5BB" />
        <div className='padding_banner'>
          <div className='container-fluid px-5'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='services_page_content my-5'>
                  <h1 className='services_page_title'>Privacy Policy</h1>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='services_page_content my-5'>
                  <p>
                    Thank you for visiting the Acompworld website. This Privacy Policy explains how Acompworld, a software development company, collects, uses, and protects the information provided by visitors to our website. By accessing or using our website, you acknowledge and accept the practices described in this policy.
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <Container className='mt-5'>

        <Row className='justify-content-center'>
          <Col md={9}>

            <div className='privacy-policy-content'>
              <h3>1. Information We Collect</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p><b> 1.1 Personal Information</b></p>
                  <p>Acompworld may collect personal information from visitors who communicate with us via email, submit requests for information, participate in surveys, or register on our site. The types of personal information we may collect include:</p>
                  <ul className='parvacy-sub-menu-content'>
                    <li> Name</li>
                    <li> Email address</li>
                    <li> Physical address (required for order placement)</li>
                  </ul>
                </li>
                <li className='mt-5'><p><b>1.2 Non-Personal Information</b></p>

                  <p>Non-personal information collected by Acompworld is used to analyze trends, track user movements on our website, and gather demographic information for internal purposes. This information helps us understand how visitors interact with our site and enables us to improve the overall user experience.</p>

                </li>
              </ul>
            </div>

            <div className='privacy-policy-content'>
              <h3>2. Use of Information</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p><b> 2.1 Personal Information</b></p>
                  <p>The personal information collected by Acompworld is primarily used for the following purposes:</p>
                  <ul className='parvacy-sub-menu-content'>
                    <li> To improve the content and functionality of our website</li>
                    <li> To notify consumers about updates to our website or any issues related to their requests.</li>
                    <li> To fulfill orders and provide the requested services.</li>
                  </ul>
                </li>
                <li className='mt-5'><p><b>2.2 Non-Personal Information</b></p>

                  <p>Non-personal information collected by Acompworld is used to analyze trends, track user movements on our website, and gather demographic information for internal purposes. This information helps us understand how visitors interact with our site and enables us to improve the overall user experience.</p>

                </li>
              </ul>
            </div>

            <div className='privacy-policy-content'>
              <h3>3. Email Communication</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p> Acompworld may use the provided email addresses to communicate with visitors who have reached out to us via email or have opted to receive         updates from our company. If you no longer wish to receive email communication from us, please inform us by sending an email to [support@acompworld.com] or    by contacting us through the information provided on our website.</p>
                </li>
              </ul>
            </div>
            <div className='privacy-policy-content'>
              <h3>4. Information Sharing</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p>Acompworld respects your privacy and does not sell, rent, or disclose your personal information to any third parties, except in the following circumstances:</p>
                  <ul className='parvacy-sub-menu-content'>
                    <li>
                      To fulfill your specific requests or orders, such as sharing your address with a shipping company for product delivery.
                    </li>
                    <li>
                      If required by law or in response to a legal process, such as a court order or government inquiry.
                    </li>
                    <li>
                      To protect the rights, property, or safety of Acompworld, our website visitors, or others.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className='privacy-policy-content'>
              <h3>5. Data Security</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p> Acompworld takes reasonable measures to protect the personal information collected on our website against unauthorized access, alteration, disclosure, or destruction. We use industry-standard security practices and technologies to safeguard the information you provide.</p>
                </li>
              </ul>
            </div>

            <div className='privacy-policy-content'>
              <h3>6. External Links</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p> Our website may contain links to external websites that are not owned or controlled by Acompworld. Please note that we are not responsible for the privacy practices or content of these third-party websites. We encourage you to review their respective privacy policies when visiting them.
                  </p>
                </li>
              </ul>
            </div>
            <div className='privacy-policy-content'>
              <h3>7. Changes to the Privacy Policy</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p> Acompworld reserves the right to modify or update this Privacy Policy at any time. We will post the revised policy on our website with the updated date. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.
                  </p>
                </li>
              </ul>
            </div>

            <div className='privacy-policy-content'>
              <h3>8. Contact Us</h3>
              <ul className='parvacy_main_line'>
                <li>
                  <p> If you have any questions, concerns, or requests regarding this Privacy Policy or our practices, please contact us using the information provided on our website.
                  </p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>


      </Container>









      <div className='default mt-5'>


        <Footer color={"#00000"} />
      </div>
    </div>
  )
}

export default PrivacyPolicy
