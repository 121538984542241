import React from 'react'
import NavbarHeader from '../components/services/navbar'
//importing container row col from react bootstrap  
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toastify from 'toastify-js';
import Button from 'react-bootstrap/Button';

import webinarService from '../services/WebinarRegistrationService';

const WebiNar = () => {
  const industries = [
    'Agriculture',
    'Automotive',
    'Banking & Financial Services',
    'Construction',
    'Consulting',
    'Consumer Goods & Services',
    'Education',
    'Energy & Utilities',
    'Engineering & Manufacturing',
    'Entertainment & Media',
    'Environment & Natural Resource',
    'Government & Public Sector',
    'Healthcare & Pharmaceuticals',
    'Hospitality & Tourism',
    'Insurance',
    'IT & Technology',
    'Legal Services',
    'Non-Profit',
    'Real Estate',
    'Retail',
    'Telecommunications',
    'Transportation & Logistics'
  ];

  const employeeRanges = [
    '1-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1,000 employees',
    '1,001-5,000 employees',
    '5,001-10,000 employees',
    '10,001-50,000 employees',
    '50,001-100,000 employees'
  ];

  const [isResponseSubmitted, setIsResponseSubmitted] = useState(false);
  const [error, setError] = useState(false);


  const navigate = useNavigate();

  const initStateData = {
    webinar_id: 2,
    name: "",
    email: "",
    phone: "",
    job_role: "",
    organization: "",
    industry: "",
    company_size: "",
    captured_on: new Date().toISOString().slice(0, 10),
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
    utm_term: "",
  }

  const [webinarData, setWebinarData] = useState(initStateData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const sanitizedValue = value.replace(/\D/g, '');
      setWebinarData({ ...webinarData, [name]: sanitizedValue });
    }
    else {
      setWebinarData({ ...webinarData, [name]: value });
    }

  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    /*  if (!webinarData.name || !webinarData.email || !webinarData.phone || !webinarData.job_role || !webinarData.organization || !webinarData.industry || !webinarData.company_size) {
       setError(true);
       return;
     } */

    try {
      const response = await webinarService.add(webinarData);
      setIsResponseSubmitted(true);
      Toastify({
        text: "Thank you for registering",
        duration: 3000,
        gravity: "top",
        position: "center",
        backgroundColor: "#0E1717",
        className: "info",
        stopOnFocus: true,
      }).showToast();
      setWebinarData(initStateData);
    } catch (error) {

      Toastify({
        text: error.message,
        duration: 3000,
        gravity: "top",
        position: "center",
        backgroundColor: "#0E1717",
        className: "error",
        stopOnFocus: true,
      }).showToast();
    }
  }



  return (
    <div>
      <div className='webinar-bg'>
        <header>
          <div className='container-fluid px-5'>
            <div className='col-lg-2'>
              <div className='logo__webinar'>
                <Link to="/">  <img src='/images/Logo-White.svg' alt='' /> </Link>
              </div>
            </div>
          </div>
        </header>

        <Container fluid className='px-5'>
          <Row>
            <Col md={6}>
              <div className='heading__webinar'>
                <h2>
                  Unlocking the Power of Generative AI: Exclusive Free Webinar For Enterprises
                </h2>

              </div>
            </Col>
            <div className='icon-date-timesection d-flex'>
              <div className='icon d-flex'>
                <img src='/images/icon/calender.png' alt='' />
                <p className='date-time'>June 28th, 2023
                  <br />
                  <span className='color-brown'>Thursday</span>
                </p>

              </div>

              <div className='icon d-flex'>
                <img src='/images/icon/watch.png' alt='' />
                <p className='date-time'>2:30 PM to 3:30 PM
                  <br />
                  <span className='color-blue'>Indian Standard Time</span>
                </p>
              </div>

              <div className='icon d-flex'>
                <img src='/images/icon/wallet.png' alt='' />
                <p className='date-time'>Free
                  <br />
                  <span className='color-green'>Indian Standard Time</span>
                </p>

              </div>
            </div>


          </Row>

        </Container>

        <Container fluid className='py-5 mt-5'>
          <Row>
            <Col md={6} className='ps-5'>
              <div className='main__div__paraha'>
                <p>Embark on a transformative journey into the realm of Generative AI with Mr. Manish Shrivastava, Digital Transformation Leader & CEO. Join our exclusive webinar as he unveils cutting-edge techniques and strategies that harness the power of Generative AI. Explore its significance in shaping the future of enterprises and uncover the endless possibilities it holds. Don't miss this opportunity to gain valuable insights from an industry expert. Secure your spot today and unlock the potential of Generative AI!</p>

                <div className='btn__learn__more__type'>
                  <Link className='text-white'>
                    You will learn
                  </Link>
                </div>

                <div className='ai__generation'>
                  <ul>
                    <li>
                      <div className='inline-manage'>
                        <i class="fa-solid fa-circle"></i> What is AI?
                      </div>

                    </li>
                    <li>
                      <div className='inline-manage'>
                        <i class="fa-solid fa-circle"></i> Introduction to Generative AI.
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li> <div className='inline-manage'><i class="fa-solid fa-circle"></i> Difference between traditional Machine Learning and Generative AI.</div> </li>

                  </ul>
                  <ul>
                    <li>
                      <div className='inline-manage'>
                        <i class="fa-solid fa-circle"></i> How Generative AI enhances enterprise revenue and efficiency.
                      </div>
                    </li>
                  </ul>
                  <ul>

                    <li>
                      <div className='inline-manage'>
                        <i class="fa-solid fa-circle"></i> Practical Examples & Demos.
                      </div>
                    </li>

                    <li><div className='inline-manage'><i class="fa-solid fa-circle"></i> Interactive Q&A session</div></li>
                  </ul>

                </div>

                <div className='planlist__section'>
                  <h5>Your panelist for this webinar is:</h5>

                  <div className='profile__section'>
                    <div className='image__section'>
                      <img src='/images/manish-sir.png' alt='' />
                    </div>
                    <div className='profile__content'>
                      <h6>Mr. Manish Shrivastava</h6>
                      <p>Digital Transformation Leader</p>
                      <span>CEO Acompworld</span>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
            <Col md={1}></Col>
            <Col md={5} className='p-0'>
              <div className='form-type'>
                {!isResponseSubmitted ?
                  <>
                    <h3>Register for this webinar</h3>
                    <form>
                      <div className='form-group mb-4'>
                        <input type='text' className='input__control' placeholder='Name' name='name' onChange={(e) => handleChange(e)} />
                      </div>

                      <div className='row form-group mb-4'>
                        <div className='col-lg-6'>  <input type='email' className='input__control' placeholder='Email' name='email' onChange={(e) => { handleChange(e) }} /></div>
                        <div className='col-lg-6'>  <input type='text' className='input__control' placeholder='Mobile Number' name='phone' onChange={(e) => { handleChange(e) }} /></div>
                      </div>

                      <div className='row form-group mb-4'>
                        <div className='col-lg-6'>  <input type='text' className='input__control' placeholder='Job role' name='job_role' onChange={(e) => { handleChange(e) }} /></div>
                        <div className='col-lg-6'>  <input type='text' className='input__control' placeholder='Oraganization' name='organization' onChange={(e) => { handleChange(e) }} /></div>
                      </div>

                      <div className='row form-group mb-4'>
                        <div className='col-lg-6'>
                          <select className='input__control select' style={{ opacity: "0.5", fontWeight: "300" }} name='industry' onChange={(e) => { handleChange(e) }}>
                            <option>Select Industry/Sector</option>
                            {industries.map((industry, index) => (
                              <option key={index} value={industry}>{industry}</option>
                            ))}
                          </select>
                        </div>
                        <div className='col-lg-6'>
                          <select className='input__control select' style={{ opacity: "0.5", fontWeight: "300" }} name='company_size' onChange={(e) => { handleChange(e) }}>
                            <option>Select Company size</option>
                            {employeeRanges.map((employeeRange, index) => (
                              <option key={index} value={employeeRange}>{employeeRange}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className='mt-5'>
                        <input type='button' className='btn__control' value="Register Now" onClick={e => handleSubmitForm(e)} />
                      </div>
                      <p className='text-check'>By clicking the register button, you automatically consent to receiving future updates <br /> about the webinar.</p>
                    </form>
                  </>
                  :
                  <>
                    <h3>Congratulations! </h3>
                    <p className='text-check'>
                      Your registration for the webinar has been successfully submitted!
                      <br/>
                      We are thrilled that you'll be joining us for this exciting event.
                      <br/>
                      
                      </p>
                      <p className='text-check'>Mark your calendar for the webinar date and time, and get ready for an enriching and engaging experience.</p>
                      <p className='text-check'>In the meantime, feel free to spread the word about the webinar to your colleagues and friends who might be interested. We believe it will be an informative and inspiring session that shouldn't be missed.</p>
                      <p className='text-check'>Thank you again for registering, and we look forward to having you as a valued participant at our webinar. Get ready for an insightful and memorable experience!</p>
                  </>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default WebiNar
