import React from 'react'
import NavbarHeader from '../services/navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import Footer from '../footer'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toastify from 'toastify-js';


import JobApplyServices from '../../services/JobApplyServices';
import CareerServices from '../../services/CareerServices';
import UploadServices from '../../services/UploadServices';
import { get } from '../../services/api';
import Utils from '../../helper/utils';

const Job = () => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isResponseSubmitted, setIsResponseSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [jobDetails, setJobDetails] = useState([]);
    const [resumePath, setUploadedresumePath] = useState("");

    const initStateData = {
        job_id: jobId,
        name: "",
        email: "",
        phone: "",
        position: "",
        resume: "",
        timeStamp: new Date(),
        job_location: ""
    }

    const [jobData, setJobData] = useState(initStateData)

    useEffect(() => {
        window.scrollTo(0, 0);
        getJobDetails();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const sanitizedValue = value.replace(/\D/g, '');
            setJobData({ ...jobData, [name]: sanitizedValue });
        }
        else {
            setJobData({ ...jobData, [name]: value });
        }

    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        try {
            const response = await JobApplyServices.add(jobData);
            handleClose();
            setIsResponseSubmitted(true);
            Toastify({
                text: "Your response has been submitted successfully",
                duration: 3000,
                gravity: "top",
                position: "center",
                backgroundColor: "#0E1717",
                className: "info",
                stopOnFocus: true,
            }).showToast();
            setJobData(initStateData);
        } catch (error) {

            Toastify({
                text: error.message,
                duration: 3000,
                gravity: "top",
                position: "center",
                backgroundColor: "#0E1717",
                className: "error",
                stopOnFocus: true,
            }).showToast();
        }
    }

    const getJobDetails = async () => {
        try {
            const response = await CareerServices.getByJobId(jobId);
            setJobDetails(response.data);
            //set position in job data
            setJobData({ ...jobData, position: response.data.job_title });
        } catch (error) {
            Toastify({
                text: error.message,
                gravity: "top",
                position: 'center',
                style: {
                    background: "#F5E5BB",
                    color: "#000000",
                }
            }).showToast();
        }
    }

    const handleUpload = (e) => {
        
        console.log("Files", e.target.files[0]);
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();

        formData.append("File", selectedFile, selectedFileName);
        formData.append("UploadFor", "Resume")
        formData.append("RequestUrl", "api.acompworld.com")
        if ((selectedFile.size) / 1000 > 2000) {
            toast.error("Size should be less than 2 mb")
        }
        else {
            UploadServices.UploadImageFor(formData).then(result => {
                if (result !== null && result != undefined) {
                   //set resume in job data
                    setJobData({ ...jobData, resume: result.data.file_path });
                }

            })

        }



    }



    const Height = {
        height: '40vh'
        // Add more CSS properties as needed
    };

    return (
        <div>
            <div className='bg__contact pb-4'>
                <NavbarHeader color="#F5E5BB" />
                <div className='padding_banner'>
                    <div className='container-fluid pt-5 px-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content'>
                                    <h1 className='services_page_title'>{jobDetails.job_title}</h1>
                                    <h4>{jobDetails.location}</h4>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='services_page_content'>
                                    <p>
                                        {jobDetails.introduction}
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Container className='mt-5'>
                <Row>
                    {/* <Col md={6}>
                        <div className='job-role'>
                            <h2>Job Role</h2>
                            <h6>{jobDetails.job_title}</h6>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='job-role'>
                            <h2>Job Location</h2>
                            <h6>{jobDetails.location}</h6>
                        </div>
                    </Col> */}
                    
                    
                    <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Apply Now</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-5'>
                            <div class="popup-content">
                                <div class="popup-main">


                                    <div class="contact-form">
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <input name="name" type="text" maxlength="50" id="txtCName" class="form-control" placeholder="*Full Name" onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <input name="email" type="text" maxlength="50" id="txtCEmail" class="form-control" placeholder="*Email" onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <input name="phone" type="text" maxlength="10" id="txtCPhone" class="form-control" placeholder="*Phone No." onChange={(e) => handleChange(e)} />


                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <input name="position" type="text" value={jobDetails.job_title} maxlength="100" id="txtCPosition" disabled="disabled" class="aspNetDisabled form-control" placeholder="*Position intereseted in" onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <input name="job_location" type="text" maxlength="100" id="txtCLocation" class="form-control" placeholder="*Your Current Location" onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group ">
                                                    <div class="file-input withbg">
                                                        <input type="file" class="file-input__input" onChange={(e) => handleUpload(e)} />
                                                        <label class="file-input__label" for="UploadCV">
                                                            <img src={jobData.resume} alt="" />
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleClose}>
                                Close
                            </Button>
                            <Link className='job__button__submit_btn' onClick={(e)=>handleSubmitForm(e)}>
                                Submit
                            </Link>
                        </Modal.Footer>
                    </Modal>


                    <Col md={8}>
                        <div className='responsibilities'>
                            <h3>Responsibilities</h3>
                            <div dangerouslySetInnerHTML={{ __html: jobDetails.job_responsibilities }} />                               
                        </div>
                    </Col>
                    <Col md={4} className='mt-10'>
                        <div className='job-role-apply'>
                            <button onClick={handleShow} className='btn btn__apply__job__modal'>Apply Now</button>

                        </div>
                    </Col>
                    
                    <Col md={8}>
                        <div className='responsibilities'>
                            <h3>Requirements/Qualifications</h3>
                                <div dangerouslySetInnerHTML={{ __html: jobDetails.required_skills }} />
                        </div>
                    </Col>

                    <Col md={8}>
                        <div className='responsibilities'>
                            <div className='salary pb-4'>
                                <h4> <b>Salary: </b>{jobDetails.salary}</h4>
                            </div>
                            <div className='pt-4'> <h4><b>Experience:</b> {jobDetails.experience}</h4> </div>
                        </div>

                    </Col>


                </Row>




            </Container>



            <div className='default mt-5'>


                <Footer color={"#00000"} />
            </div>
        </div>
    )
}

export default Job
