import api from "./api";
import APIError from "../exceptions/APIError";

class JobApplyServices {
    async add(data) {
        if (!data)
            throw APIError.badRequest("No data provided");

        if (!data.name)
            throw APIError.badRequest("Please enter name");

        if (!data.email)
            throw APIError.badRequest("Please enter email");

        if (!data.phone)
            throw APIError.badRequest("Please enter phone");
        if(!data.job_location)
            throw APIError.badRequest("Please enter job location");

        try {
            const response = await api.post("/jobApply", data);
            return response.data;
        } catch (error) {
            console.log("res", error.response);
            if (error.response && error.response.status !== 500)
                throw APIError.badRequest(error.response.data.message);
            throw APIError.internal(error.message);
        }
    }


}

export default new JobApplyServices();