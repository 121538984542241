import React from 'react'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import NavbarHeader from '../services/navbar';
import Footer from '../footer';

import {useState, useEffect} from 'react';

const ScheduleCall = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    //reload the calendlly widget
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    } 


  }, []);
  return (
    <div>
        <div className='bg__contact'>
           <NavbarHeader color="#F5E5BB"/>
                     <div className='padding_banner'>
                     <div className='container-fluid pt-3 px-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='services_page_content mt-5'>
                            <h1 className='services_page_title'>Schedule a Call</h1>
                          </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='services_page_content mt-5'>
                                <p>Ready to accelerate your digital journey? Let’s connect. Schedule a call with one of our digital transformation experts to discuss how Acompworld can empower your business to embrace the future, today.</p>
                                </div>
                            </div>
                          </div>

                    
                         </div>
                     </div>
                    </div>

                    <Container>

                    <div class="calendly-inline-widget" data-url="https://calendly.com/acompworld/30min" style={{minWidth:"320px",height:"700px"}}></div>
                         

                      </Container>


  
                       

            
                            
              
      
       <div className='default mt-5'>
  
           
      <Footer color="#00000"/> 
       </div>
    </div>
  )
}

export default ScheduleCall
