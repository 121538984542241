import React from 'react'
import NavbarHeader from '../services/navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom';
//importing usesState
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import SEO from '../seo';

const Work = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [selectedTab, setSelectedTab] = useState("enterprise");

    return (
        <div>
            <SEO
                title='Our Work - Acompworld: Success Stories in Cloud, AI & Digital Transformation'
                description='Discover how Acompworld has helped global enterprises achieve digital transformation with innovative IT solutions in cloud computing, generative AI, and automation.'
                keywords=''
            />
            <div className='bg__work pb-4'>
                <NavbarHeader color="#CEEEFD" />
                <div className='padding_banner'>
                    <div className='container-fluid mb-5 px-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>Work</h1>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Experience our diverse portfolio of successful projects across industries. With cutting-edge technology and expertise, we exceed client expectations and drive innovation. Explore how we transform businesses for remarkable results at Acompworld.</p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/work-banner-01.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>



            <section className="work__line margin-top px-5 mb-50">
                <div className='row'>
                    <div className='col-lg-10'>
                        <div className='display-flex-mobile-tab'>
                            <Link onClick={() => setSelectedTab("enterprise")} className={selectedTab === "enterprise" ? "activetab" : "tablist-btn"}>Enterprise Mobility</Link>
                            <Link onClick={() => setSelectedTab("cloud")} className={selectedTab === "cloud" ? "activetab" : "tablist-btn"}>Cloud Application</Link>
                            <Link onClick={() => setSelectedTab("bi")} className={selectedTab === "bi" ? "activetab" : "tablist-btn"}>BI & Analytics</Link>
                            <Link onClick={() => setSelectedTab("ai")} className={selectedTab === "ai" ? "activetab" : "tablist-btn"}>AI & Automation</Link>
                        </div>
                        {/* <div className="tab-content">{renderTabContent()}</div>  */}
                    </div>

                </div>                
            </section>

            {
                selectedTab === "enterprise" && (
                    <section className='best__career'>
                        {/* ENTERPRISE MOBILITY -01 SECTION  */}
                        <div className='container-fluid px-5 pt-2 mt-4'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 mobile__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/enterprise-mobility-image-01.webp' className='' width="100%" />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work">
                                        <h3>Power Plant Analytics - Plant Live NTPC


                                        </h3>
                                        <span className='by__company'>NTPC</span>

                                        <p>
                                            Acompworld successfully delivered a groundbreaking project for NTPC, India's largest power producer. This first-of-its-kind application revolutionized power station monitoring, providing NTPC with live monitoring capabilities for over 50 power stations across the country. The application offers comprehensive reports and an intuitive visual dashboard, empowering NTPC to gain valuable insights into key operational and performance metrics in real time. By leveraging advanced technology, NTPC is now able to make informed decisions, optimize efficiency, and ensure seamless power generation. Partner with Acompworld for transformative solutions that drive success in the energy sector.
                                        </p>


                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 desktop__show'>
                                    <div className="best__service__image" >
                                        <img src='/images/card/enterprise-mobility-image-01.webp' className='' width="100%" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        {/* ENTERPRISE MOBILITY -02 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="best__service__image">
                                        <img src='/images/card/Biz-ev-charger.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 px-5 pe-5'>
                                    <div className="best__service_content_work">
                                        <h3>EV Charging Station Aggregator App </h3>
                                        <span className='by__company'>Evzy</span>
                                        <p>
                                            Discover our state-of-the-art aggregator platform for EV charging stations in India. With our innovative solution, EV owners can now enjoy the convenience of booking charging sessions at partnering stations across the country. Say goodbye to range anxiety and experience hassle-free charging for your electric vehicle. Our platform offers real-time availability information, secure payment options, and a seamless user experience. Join the growing community of EV enthusiasts who trust our aggregator platform for their charging needs. Embrace the future of sustainable transportation with our reliable and user-friendly solution.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                )


            }

            {
                selectedTab === "cloud" && (
                    <section className='best__career'>
                        {/* CLOUD PROJECT -01 SECTION  */}
                        <div className='container-fluid px-5 pt-2 mt-4'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="best__service__image">
                                        <img src='/images/card/export-import.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5 px-5'>
                                    <div className="best__service_content_work ">

                                        <h3>Comprehensive Website Solution Export-Import Bank of India.</h3>
                                        <span className='by__company'>Export-Import Bank of India </span>
                                        <p>
                                            Acompworld successfully delivered a comprehensive website solution for the Export-Import Bank of India. As a leading provider of digital transformation services, Acompworld undertook the task of website design, development, and maintenance to enhance the online presence of this esteemed financial institution. The expert team at Acompworld worked diligently to create a visually appealing and user-friendly website that effectively showcases the bank's services and offerings. By leveraging cutting-edge technologies, a seamless navigation experience, responsive design, and robust functionality were ensured. Partnering with Acompworld provides transformative web solutions that drive growth and success in the global trade industry.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>

                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        {/* CLOUD PROJECT -02 SECTION */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 px-5 mobile__show'>
                                    <div className="best__service__image ">
                                        <img src='/images/card/court-mangement.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work" >
                                        <h3>Revenue Court Management System </h3>
                                        <span className='by__company'>Govt of MP</span>
                                        <p>
                                            Revenue Court Management System is a robust e-governance solution designed to streamline the workflow of revenue courts with unparalleled efficiency and transparency. Successfully deployed in over 50 districts, this cutting-edge system has revolutionized the management of revenue court processes.
                                        </p>
                                        <p>
                                            With an intuitive and feature-rich platform, revenue courts can seamlessly handle case management, document tracking, and scheduling, ensuring faster disposal of cases. The system promotes transparency by providing real-time updates and notifications to all stakeholders, including the common citizens involved in the proceedings.
                                        </p>
                                        <p>
                                            Experience the unprecedented ease and convenience offered by the Revenue Court Management System developed for our esteemed client. Join the revolution and witness the transformation of revenue court processes for enhanced efficiency and transparency.
                                        </p>


                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 px-5 desktop__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/court-mangement.webp' className='' width="100%" />
                                    </div>
                                </div>


                            </div>
                        </div>
                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>


                        {/* CLOUD PORJECT -03 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 '>
                                    <div className="best__service__image">
                                        <img src='/images/card/land-acquisition.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5 px-5'>
                                    <div className="best__service_content_work">
                                        <h3>Land Acquisition & Industry Information System </h3>
                                        <span className='by__company'>Govt of MP</span>
                                        <p>
                                            Land Acquisition and Integrated Information System (LAIIS), a project that has redefined the land acquisition process for 12 distinguished energy companies. This cutting-edge solution was developed to simplify land acquisition for industries and facilitate subsequent rehabilitation and resettlement efforts, ensuring utmost efficiency, transparency, and compliance.
                                        </p>
                                        <p>

                                            LAIIS empowers energy companies to swiftly and equitably manage their land acquisition projects. This award-winning system combines advanced technology with a user-friendly interface, streamlining the entire process from start to finish. By leveraging LAIIS, companies can benefit from accelerated workflows, well-justified decisions, and enhanced stakeholder management
                                        </p>

                                        <p>
                                            The transformative capabilities of LAIIS as it revolutionizes land acquisition practices, promotes humane approaches, and contributes to the success of energy projects. This remarkable solution was developed in close collaboration with our esteemed client, who entrusted us with the task of creating a powerful tool to optimize their land acquisition endeavors.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>
                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>


                        {/* CLOUD PROJECT -04 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 px-5 mobile__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/intranet.webp' className='' width="100%" />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work">
                                        <h3>Intranet  </h3>
                                        <span className='by__company'>NTPC</span>
                                        <p>
                                            We collaborate with NTPC to revamp their internal websites, re-designing the intranet portal using best practices in design, content, and technology. Our goal is to elevate user experience, facilitate seamless connectivity, and foster collaboration. With innovative design principles and user-friendly interfaces, we create a cohesive digital ecosystem that empowers employees and enhances productivity. This project optimizes information flow, simplifies resource access, and promotes effective communication within NTPC's network. Join us in the digital transformation journey as we revolutionize NTPC's internal websites, promoting engagement and enabling their workforce to thrive.
                                        </p>


                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 px-5 desktop__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/intranet.webp' className='' width="100%" />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </section>



                )

            }


            {
                selectedTab === "bi" && (
                    <section className='best__career'>
                        {/* BI & ANALYTICS -01 SECTION  */}
                        <div className='container-fluid px-5 pt-2 mt-4'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 px-5 mobile__show'>
                                    <div className="best__service__image ">
                                        <img src='/images/card/cloud-project-image-01.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work ">
                                        <h3>Business Analytics</h3>
                                        <span className='by__company'>NVVN</span>
                                        <p>
                                            introducing our successful delivery of the MIS App (Android & iOS) and MIS Web Portal, empowering our client with advanced Management Information System (MIS) capabilities. With an attractive user interface, intuitive navigation, and comprehensive MIS reports, our solution covers essential parameters such as NVVN's business portfolio, MOU targets, commercials, contracts, financials, power exchange data, and news updates. By optimizing MIS management, we enabled informed decision-making and operational efficiency. This project exemplifies our expertise in delivering impactful software solutions for enhanced business performance.
                                        </p>


                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 px-5 desktop__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/cloud-project-image-01.webp' className='' width="100%" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        {/* BI & ANALYTICS -02 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>

                                <div className='col-lg-6 col-md-6'>
                                    <div className="best__service__image ">
                                        <img src='/images/card/ntpc.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5 px-5'>
                                    <div className="best__service_content_work">
                                        <h3>Production Dashboard </h3>
                                        <span className='by__company'>NTPC </span>

                                        <p>
                                            compworld, in collaboration with NTPC, India's largest power producer, successfully delivered a robust production monitor dashboard for their coal mines. This advanced system enables real-time monitoring of coal production against predefined targets, providing NTPC with crucial insights for effective decision-making. The user-friendly dashboard and convenient mobile app designed specifically for top management allow NTPC to effortlessly access key performance metrics and track progress. The solution developed by Acompworld empowers NTPC to optimize coal production, streamline operations, and ensure efficient resource allocation. By partnering with Acompworld, NTPC has gained access to innovative solutions that drive productivity and enhance operational efficiency.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>
                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>

                                <div className='col-lg-6 col-md-6 px-5 mobile__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/investment-dashboard.webp' className='' width="100%" />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work ">
                                        <h3>India Investment Dashboard  </h3>
                                        <span className='by__company'>Exim Bank </span>
                                        <p>
                                            This business solution was developed specifically for EXIM Bank to effectively manage and analyze its investment portfolio and line of credit data. The application empowers users to visualize and explore India's investments in foreign nations, facilitating informed decision-making and driving economic growth.

                                        </p>
                                        <p>
                                            Stay informed about the latest investment trends and opportunities with real-time updates. The investment dashboard offers valuable insights into India's global presence, facilitating informed decision-making for businesses and policymakers.

                                        </p>
                                        <p>
                                            Unlock the potential of global investments with the EXIM Bank India Investment Dashboard. Discover the diverse investment landscape and drive economic growth. Unlock the potential of global investments with the EXIM Bank India Investment Dashboard. This solution solves the problem faced by EXIM Bank, empowering them to discover the diverse investment landscape and drive economic growth.

                                        </p>


                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 px-5 desktop__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/investment-dashboard.webp' className='' width="100%" />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                )


            }



            {
                selectedTab === "ai" && (
                    <section className='best__career'>

                        {/* AI & AUTOMATION -01 SECTION  */}
                        <div className='container-fluid px-5 pt-2 mt-4'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 '>
                                    <div className="best__service__image ">
                                        <img src='/images/card/railway-coach.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5 px-5'>
                                    <div className="best__service_content_work">
                                        <h3>Rail Coach Maintenance System</h3>
                                        <span className='by__company'>Indian Railways</span>
                                        <p>
                                            Acompworld is delighted to share the successful implementation of the Rail Coach Maintenance System (RCM), a game-changing solution for enhancing efficiency and streamlining maintenance processes. The RCM mobile app equips coach inspection and maintenance teams with digital tools, empowering them to work more efficiently and effectively. This solution ensures the timely execution of scheduled maintenance activities, reducing downtime and improving overall operational performance. With a robust web-based Management Information System (MIS) and advanced data analysis module, stakeholders gain valuable insights for informed decision-making and continuous improvement. Experience the power of digital transformation in rail coach maintenance with the innovative RCM solution provided by Acompworld.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>
                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        {/* AI & AUTOMATION -02 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 px-5 mobile__show'>
                                    <div className="best__service__image" >
                                        <img src='/images/card/sepco-streamlining.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work" >
                                        <h3>Field Services Management </h3>
                                        <span className='by__company'>Mepco </span>
                                        <p>
                                            Mepco, is a cutting-edge Field Service Management (FSM) software delivered by our team. Mepco is designed specifically for field service companies, offering a simplified and streamlined approach to business processes such as equipment installation, maintenance, and repair services. This comprehensive solution includes features like scheduling, dispatching, work order management, and service history tracking.
                                        </p>
                                        <p>
                                            Through our collaboration with a valued client, we have developed Mepco to empower field service companies with enhanced efficiency, improved customer satisfaction, and increased profitability. The successful implementation of Mepco has enabled our clients to revolutionize their operations and gain a competitive edge in the industry.
                                        </p>


                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 px-5 desktop__show'>
                                    <div className="best__service__image">
                                        <img src='/images/card/sepco-streamlining.webp' className='' width="100%" />
                                    </div>
                                </div>


                            </div>
                        </div>

                        <section className="work__line">
                            <hr className='line__gray' />
                        </section>

                        {/* AI & AUTOMATION -03 SECTION  */}

                        <div className='container-fluid px-5 pt-2 mt-50'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 px-5'>
                                    <div className="best__service__image">
                                        <img src='/images/card/managment.webp' className='' width="100%" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 pe-5'>
                                    <div className="best__service_content_work ">
                                        <h3>Digital Supply Chain Management System </h3>
                                        <span className='by__company'>Porteight </span>
                                        <p>
                                            Porteight's Digital Supply Chain Management System, is a revolutionary solution developed and delivered to a valued client. This powerful mobile and web application was specifically designed for the construction material industry, transforming the way suppliers, transporters, and buyers connect and collaborate.

                                        </p>
                                        <p>
                                            The application acts as a centralized marketplace, seamlessly bringing together suppliers, transporters, and buyers onto a single platform. It empowers suppliers to effortlessly provide construction materials such as sand, stone, and more to interested buyers while enabling transporters to seize new business opportunities by facilitating the transportation of materials between parties.

                                        </p>

                                        <p>
                                            Porteight's Digital Supply Chain Management System streamlines supply chain activities within one convenient and centralized system. Users can raise Requests for Quotation (RFQs), send Purchase Orders (POs), and perform various supply chain tasks with ease. This solution optimizes efficiency, enhances collaboration, and integrates seamless technology to deliver a transformative digital supply chain management experience.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                )

            }








            <section className="work__line">
                <hr className='line__gray' />
            </section>














            <div className='default mt-5'>
                <div className='container-fluid'>
                    <div className='footer__heading__left_to mb-5'>
                        <h2 className='center'>Have a project idea? Let's come together to build something great</h2>
                    </div>

                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn__footer'>Talk to an expert</Link>
                    </div>


                </div>

                <Footer color="#00000" />
            </div>
        </div>
    )
}

export default Work
