import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../logo';
import { FaBars, FaTimes } from 'react-icons/fa';

const NavbarHeader = ({ color }) => {
  const navigate = useNavigate();

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const closeDropdown = () => {
    setShowDropdown1(false);
    setShowDropdown2(false);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navClass = scrolled ? 'scroll-nav navbar-scrolled' : 'scroll-nav';

  const [showCollapse, setShowCollapse] = useState(false);
  const [isClicked, setIsClickeded] = useState(false);

  const handleToggle = () => {
    setShowCollapse(!showCollapse);
    setIsClickeded(!isClicked);
  };





  const toenterpriseMoblity = () => {
    navigate('/services/enterprise-mobility');
  };

  const toCloudApplication = () => {
    navigate('/services/cloud-applications');
  };

  const toBiAnalytics = () => {
    navigate('/services/bi-analytics');
  };

  const toAiAutomation = () => {
    navigate('/services/ai-automation');
  };

  const toAbout = () => {
    navigate('/about');
  };

  const tocareers = () => {
    navigate('/careers');
  };

  const toBlog = () => {
    navigate('/blog');
  };


  return (
    <div>
      <Navbar fixed="top" className={navClass}>
        <Container fluid className="">
          <Link to="/" className="nav_brand navbar-brand">
            <Logo color={color} />
          </Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          >
            {isClicked ? (
              <FaTimes color={color} />
            ) : (
              <FaBars color={color} />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" in={showCollapse}>
            <Nav className="">
              <Link className="nav_link_ligth_theme">
                <NavDropdown
                  title="Services"
                  id=""
                  show={showDropdown1}
                  onClick={toggleDropdown1}
                  onSelect={closeDropdown}
                  href="/services"
                >
                  <NavDropdown.Item className="dropdown__navbar" onClick={toenterpriseMoblity}>
                    <Link>Enterprise Mobility</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown__navbar" onClick={toCloudApplication}>
                    <Link>Cloud Application</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown__navbar" onClick={toBiAnalytics}>
                    <Link>BI & Analytics</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown__navbar" onClick={toAiAutomation}>
                    <Link>AI & Automation</Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Link>
              <Link to="/work" className="nav_link_ligth_theme nav-link">
                Work
              </Link>
              <Link className="nav_link_ligth_theme">
                <NavDropdown
                  title="Company"
                  id="collasible-nav-dropdown"
                  show={showDropdown2}
                  onClick={toggleDropdown2}
                  onSelect={closeDropdown}
                  className=""
                  href="/careers"
                >
                  <NavDropdown.Item className="dropdown__navbar" onClick={toAbout}>
                    <Link>About Us</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown__navbar" onClick={tocareers}>
                    <Link>Careers</Link>
                  </NavDropdown.Item>
                   </NavDropdown>
              </Link>
              <Link to="/blogs" className="nav_link_ligth_theme nav-link">
              Insights
              </Link>
            </Nav>
            <Nav className="ms-auto">
              <Link to="/contact" className="nav_link_ligth_theme nav-link">
                Contact
              </Link>
              <a href="/schedule-call" className="btn__link_light_theme nav-link">
                Schedule a call
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarHeader;