import { React, useRef } from 'react'
import NavbarHeader from '../services/navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CareerServices from '../../services/CareerServices';
import { Element } from 'react-scroll';

import Toastify from 'toastify-js';
import Utils from '../../helper/utils';
import SEO from '../seo';

const Careers = () => {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [careerData, setCareerData] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
        getAllJobs();
    }, []);

    const getAllJobs = async () => {
        try {
            const response = await CareerServices.getAll();
            setCareerData(response.data);
        } catch (error) {
            Toastify({
                text: error.message,
                gravity: "top",
                position: 'center',
                style: {
                    background: "#F5E5BB",
                    color: "#000000",
                }
            }).showToast();
        }
    }

    const navigateToJobTittle = (jobId) => {
        navigate(`/job/${jobId}`);
    }



    return (
        <div>
            <SEO
                title='Careers - Acompworld: Join Us in Shaping the Future of Technology'
                description='Explore exciting career opportunities at Acompworld and be part of the team driving innovation in generative AI, automation, and cloud computing.'
                keywords=''
            />
            <div className='bg__career pb-4'>
                <NavbarHeader color="#D4EDAA" />
                <div className='padding_banner  marging-d-pading'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>Career</h1>
                                </div>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Be part of an innovation driven company, where your talent and passion for technology can thrive. We offer exciting career opportunities across various domains, empowering you to make a meaningful impact and grow professionally.</p>
                                    {/* Ghost button to navigate to Current Opening Section */}
                                    <div className='btnGhost' onClick={handleClick}>
                                        View Current Openings
                                    </div>
                                </div>
                            </div>
                        </div>
                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/career-banner-image.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>

            <section className='padding-section mt-50'>
                <div className='container-fluid px-3'>
                    <h2 className='career__title'>Benefits that make a difference</h2>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='career__benefits__images'>
                                <img src='/images/card-benefit-01.webp' alt='' className='' />
                            </div>
                            <div className='career__benefit__content'>
                                <h3 className=''>For Employees</h3>
                                <ul>
                                    <li><b>Competitive Compensation:</b> Attractive pay packages that reflect the value you bring.</li>
                                    <li><b>Continuous Learning:</b> Access to state-of-the-art training platforms, workshops, and courses.</li>
                                    <li><b>Professional Growth:</b> Clear career paths and opportunities for advancement within the company.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='career__benefits__images'>
                                <img src='/images/card-benefit-02.webp' alt='' className='' />
                            </div>
                            <div className='career__benefit__content'>
                                <h3 className=''>Work Life Balance</h3>
                                <ul>
                                    <li><b>Remote Work Opportunities:</b> Work from home facility for suitable roles</li>
                                    <li><b>Generous Leave Policies:</b> Earned leave and mandatory vacation days to ensure you recharge fully.</li>
                                    <li><b>Health and Wellness:</b> Comprehensive health insurance plans for you.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='career__benefits__images'>
                                <img src='/images/card-benefit-03.webp' alt='' className='' />
                            </div>
                            <div className='career__benefit__content'>
                                <h3 className=''>Culture</h3>
                                <ul>
                                    <li><b>Diverse & Inclusive:</b> A global team representing multiple cultures, backgrounds, and perspectives.</li>
                                    <li><b>Collaborative Environment:</b> Open-door policies, brainstorming sessions, and team-building events to foster unity.</li>
                                    <li><b>Innovation-driven:</b> Regular hackathons, innovation challenges, and encouragement to pursue individual projects</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work__line" ref={ref}>
                <hr className='line__gray' />
            </section>

            <section className="career-padding mt-80" >
                <div className='container-fluid p-0 mb-5'>
                    <div className='row'>
                        <div className='col-lg-6 mb-5'>
                            <div className='about-bottom'>
                                <h3>Featured Roles</h3>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-5'>
                            <div className='about-bottom'>
                                <p>Discover exciting roles at Acompworld across domains such as software development, project management, data analytics, cybersecurity, and more.</p>
                            </div>
                        </div>

                        <div className='col-lg-12 mt-4'>
                            <div className='row'>
                                {careerData && careerData.map((item, index) => (
                                    <>
                                        <div className='col-lg-10 mb-4'>

                                            <div className='content__apply'>
                                                <h5>Job Role : {item.job_title} </h5>
                                                <p><b>Location:</b> &nbsp; {item.job_location}</p>
                                                <p className='mb-3'><b>Experience:</b> &nbsp; {item.experience}</p>
                                            </div>

                                        </div>
                                        <div className='col-lg-2 mb-4'>
                                            <div className='btn__apply__now'>
                                                <a style={{ cursor: 'pointer' }} onClick={e => navigateToJobTittle(item.id)}>apply now <img src='/images/arrow-r.png' /> </a>
                                            </div>
                                        </div>
                                    </>))}
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="work__line">
                <hr className='line__gray' />
            </section>

            <section className='best__career mt-100'>
                <div className='container-fluid px-5'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6'>
                            <div className='best__service_content'>
                                <h3>Life at Acompworld </h3>
                                <p>
                                    At Acompworld, we foster a vibrant and inclusive work culture. From engaging team-building activities to employee recognition programs, we create an environment where you can grow, collaborate, and celebrate achievements together.
                                </p>

                                <p>
                                    Take the next step in your career and join Acompworld. Be part of a dynamic team, experience the benefits that set us apart, explore featured roles, and embrace a fulfilling life at Acompworld.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-1 displaynone-div'></div>

                        <div className='col-lg-6 col-md-6'>
                            <div className='best__service__image'>
                                <img src='/images/career-card-image.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work__line">
                <hr className='line__gray' />
            </section>


            <div className='container-fluid px-1 mt-100'>
                <div className='footer__heading__left px-3'>
                    <h2 className='careerquote'>“At Acompworld, our greatest asset isn't the innovative technology we build, but the people who build it. We take immense pride in our diverse team of visionaries, thinkers, and doers who continuously drive us forward. As we chart our course through an exciting era of digital transformation, our team remains at the heart of all we do. By joining us, you’re not just starting a job, you're making a mark on the global enterprise landscape. I invite you to be a part of our journey, to grow with us, and to help shape a future where business meets intelligence.”</h2>
                </div>

                <div className='col-lg-12 mt-5 px-5'>
                    <div className='acom__head'>
                        <h6>Manish Shrivastava</h6>
                        <p>CEO, Acompworld</p>
                    </div>
                </div>


            </div>

            <div className='default mt-5'>


                <Footer color="#00000" />
            </div>
        </div>
    )
}



export default Careers
