import React from 'react'
import NavbarHeader from './navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import SEO from '../seo';

const BiAnalytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);







    return (
        <div>
             <SEO
  title='Business Intelligence & Analytics - Acompworld: Unlock Data-Driven Insights'
  description="Drive smarter business decisions with Acompworld's business intelligence and analytics solutions, utilizing predictive analytics and data mining."
  keywords=''
/>
            <div className='bg__bianalytics pb-4'>
                <NavbarHeader color="#FFF9C0" />
                <div className='padding_banner marging-d-pading'>
                    <div className='container-fluid '>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>BI and Analytics</h1>
                                    <h2>Unleashing Data-Driven Insights</h2>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Unlock your data's potential with Acompworld's advanced BI and Analytics solutions. Gain valuable insights, optimize decision-making, and           drive business growth across finance, healthcare, retail, and more. Empower your enterprise with data-driven success today!</p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/bilala.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>
            <section className="mt-5">
                <div className='container-fluid px-5'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Uncover Hidden Opportunities Harness Data Insights for Business Growth.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Mitigate Risk: Make Informed Decisions with Predictive Analytics.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Forecast with Confidence: Leverage Predictive Analytics for Accurate Business Plannin</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Uncover Patterns and Trends: Drive Innovation through Data Exploration.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="work__line">
                <hr className='line__gray' />
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content">
                                <h3>Why is our service the best?</h3>
                                <p>
                                    At Acompworld, we offer specialized BI and Analytics solutions to help businesses unlock the power of their data. With our expertise, customized solutions, and advanced tools, we provide actionable insights and data visualization that drive real results.
                                </p>

                                <p>
                                    Our scalable solutions, robust data security, and competitive edge enable businesses to make informed decisions, optimize growth, and stay ahead in the market.
                                </p>

                                <p>
                                    Choose Acompworld for value-added BI and Analytics solutions that transform raw data into meaningful business outcomes.
                                </p>
                            </div>
                        </div>
                        <div className='col-1 displaynone-div'>

                        </div>

                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/card/bi-card-image.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work mt-100" >
                <div className="container-fluid">
                    <div className='col-lg-1 col-md-3 col-3'>
                        <div className='work__image'>
                            <img src='/images/abg.png' className='' alt='' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-8 col-sm-12 mt-5">
                            <div className="work-box">
                                <div className="work-title">
                                    <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='default'>
                <div className='container-fluid'>
                    <div className='footer__heading'>
                        <h2 class="center">Have a project idea? Let's come together to build something great</h2>
                    </div>
                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn__footer'>Talk to an expert</Link>
                    </div>

                </div>
                <Footer color="#00000" />
            </div>
        </div>
    )
}

export default BiAnalytics
