import React from 'react'
import NavbarHeader from './navbar'
import Footer from '../footer'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import SEO from '../seo';

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);





    return (
        <>
        <SEO
  title='IT Services - Acompworld: Advanced Cloud, Generative AI, Automation & BI Solutions'
  description="Unlock business growth with Acompworld's IT services, including generative AI, cloud applications, automation, business intelligence, and data analytics."
  keywords=''
/>
      
        <div>
            <div className='bg__services pb-4'>
                <NavbarHeader color={"#FFDCB3 "} />
                <div className='padding_banner marging-d-pading'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='services_page_content my-5'>
                                    <h1 className='services_page_title'>Enterprise Mobility</h1>
                                    <h2>Empowering Your Business Anywhere</h2>
                                </div>
                            </div>

                            <div className='col-md-5 mb-4'>
                                <div className='services_page_content my-5'>
                                    <p>Acompworld excels in enterprise mobility solutions, from app development to device management. Serving retail, healthcare, logistics, field service, and finance, we enhance productivity, streamline processes, and drive innovation. Experience our best-in-class service today</p>
                                </div>
                            </div>
                        </div>

                        <article className='service__banner__image'>
                            <picture>
                                <img src='/images/services-banner.webp' alt='' className='' width="100%" />
                            </picture>
                        </article>
                    </div>
                </div>
            </div>
            <section className="mt-5">

                <div className='container-fluid px-5'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Enhance Employee Productivity: Empower Your Team with Mobile Task Management.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Enable On-the-Go Decision Making with Mobile Business Intelligence and Analytics.</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Empower Field Service Efficiency with Acompworld's Cutting-Edge Mobility Innovations..</p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='service__card__wrapper'>
                                <p>Real-Time Collaboration: Connect Teams and Accelerate Business Growth with Enterprise Mobility</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="work__line">
                <hr className='line__graynew' />
            </section>


            <section className='best__service mt-80'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6'>
                            <div className="best__service_content">
                                <h3>Why is our service the best?</h3>
                                <p>
                                    Acompworld stands out as the ideal choice for enterprise mobility solutions. Our experienced team brings deep expertise in the latest mobile technologies, ensuring customized solutions that precisely meet your requirements.
                                </p>

                                <p>
                                    We prioritize data security, implementing robust measures to safeguard your business information. Our scalable solutions adapt to your growing needs, providing flexibility for future expansion. With a focus on delivering seamless user experiences, we create intuitive and engaging mobile applications
                                </p>

                                <p>
                                    Stay ahead of the competition with our innovative approach and cutting-edge solutions. Partner with Acompworld to empower your business in the digital era.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-1 displaynone-div'>

                        </div>

                        <div className='col-lg-6 col-md-6'>
                            <div className="best__service__image">
                                <img src='/images/card/enterprize-best-image-01.webp' className='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work mt-100">
                <div className="container-fluid">
                    <div className='col-lg-1 col-md-3 col-3'>
                        <div className='work__image'>
                            <img src='/images/abg.png' className='' alt='' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12 mt-5">
                            <div className="work-box">
                                <div className="work-title">
                                    <p>An India based software development company, serving clients globally since 2012 by offering a wide range of software, web and mobile app development services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='default'>
                <div className="container-fluid ">
                    <div className='footer__heading'>
                        <h2 class="center">Have a project idea? Let's come together to build something great</h2>
                    </div>
                    <div className='footer__btn'>
                        <Link to="/contact" role='button' className='btn__footer'>Talk to an expert</Link>
                    </div>

                </div>
                <Footer color={"#00000"} />
            </div>
        </div>
        </>
    )
}

export default Services;
